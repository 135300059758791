import React, {useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Intro from './Intro'
import About from './About'
import Carousel from './Carousel'
import Sugerencias from './Sugerencias'
import Container from './Container'
import { Auth } from "../context/AuthContext";

import { InstagramOutlined, FacebookOutlined, ToolOutlined, UserOutlined,WhatsAppOutlined,PhoneOutlined, HomeOutlined , ReadOutlined  } from '@ant-design/icons';
import FueraDeCarta from './FueraDeCarta';

const useStyles = makeStyles((theme) => ({
    icons: {
        display: 'flex'
    }
}));

function Home() {
  
  const classes = useStyles();
  const { usuario } = useContext(Auth);


  return(
    <>
    <nav>
                    <ul className="nav-flex-row">
                        <li className="nav-item">
                            <Link to="/"><HomeOutlined style={{ fontSize: '28px'}}/></Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/carta"><ReadOutlined style={{ fontSize: '28px'}}/></Link>
                        </li>
                        
                        <li className="nav-item">
                            <a target="_blank" href="https://www.facebook.com/El-Lindero-105823741200986" className={classes.icons}><FacebookOutlined style={{ fontSize: '28px'}}   /></a>
                        </li>
                        <li className="nav-item">
                            <a target="_blank" href="https://www.instagram.com/linderogastrotaberna/" className={classes.icons} ><InstagramOutlined style={{ fontSize: '28px'}} /></a>
                        </li>
                        <li className="nav-item">
                            <a href="https://wa.me/+34675025494"  className={classes.icons} > <WhatsAppOutlined style={{ fontSize: '28px'}} /></a>
                        </li>
                        <li className="nav-item">
                            <a href="tel:675025494" className={classes.icons}><PhoneOutlined  style={{ fontSize: '28px'}}/></a>

                        </li>
                        

                        <li className="nav-item">
                            {usuario ? <Link to="/platos" className={classes.icons}><ToolOutlined style={{ fontSize: '28px'}} /></Link>: <Link to="/login" className={classes.icons}><UserOutlined style={{ fontSize: '28px'}} /></Link>} 
                        </li>
                    </ul>
                </nav>
        <Intro />
        <FueraDeCarta />
        <Sugerencias />
        <About />

        {/*<Carousel />*/}
        <Container />




      </>
    );
  
}
export default Home;