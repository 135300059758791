
const denominaciones = [];
denominaciones[1] = "Ribera de Duero";
denominaciones[2] = "Rioja";
denominaciones[3] = "Rueda";
denominaciones[4] = "Montilla - Moriles";
denominaciones[5] = "Jerez";
denominaciones[6] = "Valdepeñas";
denominaciones[7] = "Ribeiro";
denominaciones[8] = "Cariñena";
denominaciones[9] = "Toro";
denominaciones[10] = "Somontano";
denominaciones[11] = "Vinos de la Tierra de Cádiz";
denominaciones[12] = "Rías Baixas";

const grupos = [];
grupos[1]= "Comenzamos";
grupos[2]= "Ensaladas y entrantes";
grupos[3]= "Revueltos y verduras";
grupos[4]= "Fritos y caseros";
grupos[5]= "Pescados";
grupos[6]= "A la parrilla";
grupos[7]= "Sugerencias";
grupos[8]= "Postres";
grupos[9]= "Bebidas";
grupos[10]= "Fuera de Carta";

const options = [
    { label: 'Cacahuete', value: 'cacahuete' },
    { label: 'Apio', value: 'apio' },
    { label: 'Altramuces', value: 'altramuces' },
    { label: 'Frutos de cáscara', value: 'cascara' },
    { label: 'Crustáceos', value: 'crustaceos' },
    { label: 'Gluten', value: 'gluten' },
    { label: 'Huevo', value: 'huevo' },
    { label: 'Lácteos', value: 'lacteos' },
    { label: 'Moluscos', value: 'molusco' },
    { label: 'Mostaza', value: 'mostaza' },
    { label: 'Pescado', value: 'pescado' },
    { label: 'Semillas de sésamo', value: 'sesamo' },
    { label: 'Soja', value: 'soja' },
    { label: 'Sulfitos', value: 'sulfitos' },
];

const allowedMails = ['juancalero@gmail.com', 
                    'carloscandelariovazquez@gmail.com',
                    'linderotaberna@gmail.com',
                    'ellinderocomandas@gmail.com']

export {denominaciones, grupos, allowedMails, options};