import firebase from 'firebase/app';

const db = firebase.database().ref("/comandas")

const getAll = () => {
  return db;
};

const create = (data) => {
  return db.push(data);
};

const update = (key, data) => {
  return db.child(key).update(data);
};

const remove = (key) => {
  console.log("key",key)
  return db.child(key).remove();
};

const removeAll = () => {
  return db.remove();
};


export default {
  getAll,
  create,
  update,
  remove,
  removeAll,
};
