import React, { useEffect, useContext, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import FormDialog from './Modal';

import { Auth } from "../context/AuthContext";
import { denominaciones, grupos, allowedMails } from "../config/constantes"
import { useHistory } from "react-router-dom";

import { Table, Tag, Space, Input, Button, Select, Avatar, Popconfirm, message, Collapse, Modal } from 'antd';

import firebase from 'firebase/app';
import { v4 as uuidv4 } from 'uuid';
import Moment from 'react-moment';

import ComandasDataService from "../services/ComandasService";
import CartaDataService from "../services/CartaService";

import MenuAdmin from '../components/MenuAdmin'

import { InstagramOutlined, FacebookOutlined, ToolOutlined, UserOutlined, NotificationOutlined, DeleteOutlined, EditOutlined, CloseCircleOutlined, CloseCircleTwoTone, CheckCircleTwoTone, PlusOutlined, MinusOutlined } from '@ant-design/icons';

const { Option } = Select;
const { Panel } = Collapse;
const { TextArea } = Input;

const useStyles = makeStyles((theme) => ({
  carta: {
    padding: "10px 20px 10px 20px",
    textAlign: "center",
    "& p": {
      textAlign: "left"
    }

  },
  cartaItems: {
    display: "flex",
    marginBottom: '5px',
    justifyContent: "space-between",
    "& .ant-btn .anticon":
    {
      verticalAlign: "baseline",
      color: "lightgray",

    },
    "& span.ant-select-arrow": {
      width: "auto",
    }
  },
  plato: {
    display: "flex",
    justifyContent: "flex-start",
    width: "33%",
  },
  h2: {
    textAlign: "left",
    fontSize: '20px'
  },
  grupo: {
    paddingInlineStart: '0px'
  },
  textCenter: {
    textAlign: "center"
  },
  comandaWrapper: {
    border: '1px solid #eee',
    padding: '8px'
  },
  pedidoComanda: {
    textAlign: 'left',
    listStyle: 'none',
    paddingLeft: 0,
  },
  itemPedidoComanda: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '3px'
  },
  avatar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px',
    background: '#eee'
  },
  mediaIcon: {
    width: "10px",
    height: "20px",
    backgroundColor: "white",
    borderBottomLeftRadius: "10px",
    borderTopLeftRadius: "10px",
    border: "2px solid #d9d9d9",
    display: "inline-block",
    verticalAlign: "bottom",
    marginRight: "10px"

  },
  enteraIcon: {
    width: "20px",
    height: "20px",
    backgroundColor: "white",
    borderBottomRightRadius: "10px",
    borderTopRightRadius: "10px",
    borderBottomLeftRadius: "10px",
    borderTopLeftRadius: "10px",
    border: "2px solid #d9d9d9",
    display: "inline-block",
    verticalAlign: "bottom"

  },
  platocomanda: {
    flex: "1 1 auto"
  },
  editcomanda: {
    marginRight: "3px"
  },
  iconoyplato: {
    display: "flow-root",
    textAlign: "left",
    justifyContent: "space-between",
    "& span": {
      textAlign: "left",
      marginRight: "3px"
    },
  },
  botones: {
    display: "flex",

  },
  cantidad: {
    border: "1px solid #f0f0f0",
    minWidth: "30px",
    maxHeight: "32px",
    marginLeft: "3px",
    marginRight: "3px",
    verticalAlign: "baseline",
    paddingTop: "4px"

  }


}));

const Stock = ({ history }) => {

  const classes = useStyles();


  const { usuario } = useContext(Auth);
  const [nombre, setNombre] = useState(null)

  const colorestags = ["", "magenta", "red", "volcano", "orange", "gold", "lime", "green", "cyan", "blue", "geekblue", "purple"]

  const initialValue = []
  const [carta, setCarta] = useState(initialValue);
  const [cartaVinos, setCartaVinos] = useState(initialValue);

  const [mesa, setMesa] = useState(initialValue);
  const [comanda, setComanda] = useState(initialValue);

  const onDataChange = (items) => {
    let cartaAux = [];

    items.forEach((item) => {
      let key = item.key;
      let data = item.val();
      cartaAux.push({
        key: key,
        platos: data.platos,
        grupo: data.nombre,
      });
    });
    //cartaAux.sort((a, b) => (a.grupo > b.grupo) ? 1 : -1)

    setCarta(cartaAux);
  };

  useEffect(() => {
    CartaDataService.getAll().on("value", onDataChange);

    return () => {
      CartaDataService.getAll().off("value", onDataChange);
    };
  }, []);


  const aumentaStock = (plato, key, grupo) => {

    const itemStock = {
      media: plato.media ? plato.media : '',
      racion: plato.racion ? plato.racion : '',
      nombreplato: plato.nombreplato,
      publicado: plato.publicado,
      stock: plato.stock ? plato.stock + 1 : 1
    }
    // alert('/carta/grupos/'+grupo+'/platos/'+key)
    CartaDataService.update(grupo + '/platos/' + key, itemStock)
      .then(() => {
        console.log("The item was updated successfully!", itemStock);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const reduceStock = (plato, key, grupo) => {

    const itemStock = {
      media: plato.media ? plato.media : '',
      racion: plato.racion ? plato.racion : '',
      nombreplato: plato.nombreplato,
      publicado: plato.publicado,
      stock: plato.stock ? plato.stock - 1 : 0
    }
    // alert('/carta/grupos/'+grupo+'/platos/'+key)
    CartaDataService.update(grupo + '/platos/' + key, itemStock)
      .then(() => {
        console.log("The item was updated successfully!");
      })
      .catch((e) => {
        console.log(e);
      });
  }



  let allcarta = [];
  let allcartavinos = [];
  let dataSource = [];
  let dataSourceVinos = []
  let aux
  let keys

  return (
    <>
      {/* {usuario && <div className={classes.avatar}>
        <Button onClick={(e) => { window.open('/comanda', "_self") }}>Nueva</Button>
        <Button onClick={(e) => { window.open('/comandasboard', "_self") }}>Comandas</Button>

        <Avatar src={usuario.photoURL} />
      </div>} */}

      <MenuAdmin titulo="Control de Stock" />

      <div className={classes.carta}>

        <>
          {usuario && allowedMails.includes(usuario.email) && <>

            <Collapse>
              {carta.map((datos, idx) => {
                if (idx != 10 && datos.platos != null)
                  return (
                    <Panel header={datos.grupo} key={idx} >
                      {
                        Object.keys(datos.platos).length > 0 && Object.values(datos.platos).map((itemplato, j) => {
                          let keys = Object.keys(datos.platos);
                          //Object.values(datos.platos).map(itemplato => {
                          if (itemplato.publicado) return <div key={itemplato.nombreplato} className={classes.cartaItems}>
                            <div className={classes.iconoyplato}>
                              <CheckCircleTwoTone twoToneColor="#52c41a" />
                              <span>{itemplato.nombreplato}</span>
                            </div>
                            <div className={classes.botones}>
                              <Button shape="circle" icon="minus" key={itemplato.nombreplato + "-mas"} onClick={(e) => { reduceStock(itemplato, keys[j], idx + 1) }} type="default"></Button>
                              <div className={classes.cantidad}>{itemplato.stock ? itemplato.stock : " "}</div>
                              <Button shape="circle" icon="plus" key={itemplato.nombreplato + "-menos"} onClick={(e) => { aumentaStock(itemplato, keys[j], idx + 1) }} type="default"></Button>
                            </div>
                          </div>
                          else return <div key={itemplato.nombreplato} className={classes.cartaItems}>
                            <div className={classes.iconoyplato}>
                              <CloseCircleTwoTone twoToneColor="#eb2f96" />
                              <span>{itemplato.nombreplato}</span>
                            </div>
                            <div className={classes.botones}>
                              <Button shape="circle" icon="minus" key={itemplato.nombreplato + "-mas"} onClick={(e) => { reduceStock(itemplato, keys[j], idx + 1) }} type="default"></Button>
                              <div className={classes.cantidad}>{itemplato.stock ? itemplato.stock : " "}</div>
                              <Button shape="circle" icon="plus" key={itemplato.nombreplato + "-menos"} onClick={(e) => { aumentaStock(itemplato, keys[j], idx + 1) }} type="default"></Button>
                            </div>
                          </div>
                        })
                      }
                    </Panel>)
              })
              }
            </Collapse>

          </>
          }

        </>
      </div>
    </>
  );

}
export default Stock;

