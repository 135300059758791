import React, { useEffect, useContext, useState } from "react";
import Header from "../components/Header";
import { useHistory } from "react-router-dom";

import { Layout } from "antd";
import { Table, Tag, Space, Input, Button } from 'antd';

import { Auth } from "../context/AuthContext";
import { withRouter } from "react-router";

import firebase from 'firebase'

import { CheckCircleTwoTone, CloseCircleTwoTone, EditOutlined, EyeTwoTone, EyeInvisibleTwoTone, SearchOutlined } from '@ant-design/icons';

import { useList } from "react-firebase-hooks/database";

import VinosDataService from "../services/VinosService";
import MenuAdmin from '../components/MenuAdmin'

import {denominaciones} from "../config/constantes"
import {allowedMails} from "../config/constantes"

const Vinos = ({ history }) => {
    const { Content, Footer } = Layout;
    const { usuario } = useContext(Auth);
    const [nombre, setNombre] = useState(null);

    const initialValue = []

    let allcarta = [];
    let dataSource = [];
    const [vinos, loading, error] = useList(VinosDataService.getAll());

    const [value, setValue] = useState('');

    const [cartaVinos, setCartaVinos] = useState(dataSource);
    const [cartaVinosAux, setCartaVinosAux] = useState(dataSource);




    useEffect(() => {
        vinos.length > 0 && vinos.map((item, i) => {
            dataSource.push({
                key: item.key,
                vino: item.val().vino,
                anyo: item.val().anyo ? item.val().anyo : "",
                bodega: item.val().bodega ? item.val().bodega : "",
                origen: item.val().origen ? item.val().origen : "",
                publicado: item.val().publicado ? item.val().publicado : "",
                envejecimiento: item.val().envejecimiento ? item.val().envejecimiento : "",
                uva: item.val().uva ? item.val().uva : "",
                precio: item.val().precio ? item.val().precio : "",
                orden: item.val().orden ? item.val().orden : "",
            })
        })
        setCartaVinos(dataSource)
        setCartaVinosAux(dataSource)
    }, [vinos]);






    const FilterByNameInput = (
        <Input
        prefix={<SearchOutlined />}
            placeholder="Buscar vino"
            value={value}
            onKeyDown={(e) => { if (e.key === 'Backspace') { setCartaVinos(cartaVinosAux) } }}
            onChange={e => {
                const currValue = e.target.value;
                const cartaVinosAux = cartaVinos
                setValue(currValue);
                const filteredData = cartaVinosAux.filter(entry => {
                    return entry.vino.toLowerCase().includes(currValue.toLowerCase())
                }
                );
                setCartaVinos(filteredData);
            }}
        />
    );



    const columns = [
        {
            title: '',
            key: 'publicado',
            responsive: ['lg'],
            render: (data) => {
                //console.log("data", data)
                if (typeof data !== 'undefined' && typeof data.publicado !== 'undefined' && data.publicado === true) {
                    return <><CheckCircleTwoTone twoToneColor="#52c41a" /> </>
                }
                else return <><CloseCircleTwoTone twoToneColor="#eb2f96" /> </>


            },
        },
        {
            title: 'Orden',
            key: 'orden',
            dataIndex: 'orden',
            sorter: (a, b) => a.orden - b.orden,
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'ascend',
            render: (data) => { return (<>{data}</>) }
        },
        {
            title: 'Vino',
            key: 'vino',
            dataIndex: 'vino',
            sorter: (a, b) => a.vino.localeCompare(b.vino),
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'ascend',
            render: (data) => { return (<>{data}</>) }
        },
        {
            title: 'Origen',
            key: 'origen',
            dataIndex: 'origen',
            sorter: (a, b) => a.origen.localeCompare(b.origen),
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'ascend',
            render: (data) => { return (<>{denominaciones[data]}</>) }
        },
        // {
        //     title: 'precio',
        //     key: 'precio',
        //     dataIndex: 'precio',
        //     sorter: (a, b) => a.precio.localeCompare(b.precio),
        //     sortDirections: ['descend', 'ascend'],
        //     defaultSortOrder: 'ascend',
        //     render: (data) => { return (<>{data}</>) }
        // },
        {
            title: '',
            responsive: ['lg'],
            key: 'publicado',
            render: (data) => {
                if (typeof data !== 'undefined' && typeof data.publicado !== 'undefined' && data.publicado === true) {
                    return <><Button key={data.vino} shape="circle" type="default" onClick={(e) => { cambiarPublicacion(data, e) }} ><EyeInvisibleTwoTone twoToneColor="#eb2f96" /></Button></>
                }
                else return <><Button key={data.vino} shape="circle" type="default" onClick={(e) => { cambiarPublicacion(data, e) }} ><EyeTwoTone twoToneColor="#52c41a" /></Button></>
            },
        },
        {
            title: ' ',
            key: 'action',
            render: (data) => (<><Button key={data.vino} shape="circle" type="default" onClick={(() => { loadVino(data) })}><EditOutlined /></Button></>
            ),
        }
    ];


    useEffect(() => {
        //let allowedMails = ['juancalero@gmail.com', 'carloscandelariovazquez@gmail.com']
        if (usuario === null || !allowedMails.includes(usuario.email)) {
            history.push("/");
        }
        usuario ? usuario.displayName ? setNombre(usuario.displayName) : setNombre(usuario.email) : setNombre(null)
    }, [usuario]);

    const loadVino = (data) => {
        console.log("data", data);
        history.push(
            {
                pathname: '/modificavino',
                datos: data,
                idvino: data.key
            }
        );
    }



    const cambiarPublicacion = (data, e) => {
        e.preventDefault();
        if (data.publicado === undefined) data.publicado = false
        var postData = {
            vino: data.vino,
            anyo: data.anyo,
            bodega: data.bodega,
            origen: data.origen,
            envejecimiento: data.envejecimiento,
            uva: data.uva,
            precio: data.precio,
            orden: data.orden,
            publicado: !data.publicado
        }

        var updates = {};
        updates['/vinos/' + data.key] = postData;
        var post = firebase.database().ref().update(updates);



        //e.target.reset();
    }


    const mystyle = (window.innerWidth > 900) ? { padding: "0px 50px", marginTop: 40 } : { padding: "0px", marginTop: 10 }
    const tableWrapper = (window.innerWidth > 900) ? { background: "#fff", minHeight: "80vh", padding: 24 } : { background: "#fff", minHeight: "80vh", padding: 0 }
    const inputStyle = {marginTop:10, padding:10, marginBottom: 0}
    return (
        <Layout >
            {/* <Header
                onBack={() => null}
                titulo="Vinos"
                subtitulo={`Bienvenido ${nombre}`}
            /> */}
            <MenuAdmin titulo="Vinos" />
            <div style={inputStyle}>{FilterByNameInput}</div>

            <Content style={mystyle}>
                <div style={tableWrapper}>

                    {!loading &&
                        <Table   size="small" rowKey="id" dataSource={cartaVinos} columns={columns} pagination={false} />}

                    {/* <ul> */}
                    {/* {vinos.map(vino => { 
                            return <li>
                                    {vino.val().vino} - {vino.val().bodega} 
                                    <EditOutlined onClick={(() => { loadVino(vino) })} />
                                    
                                </li> 
                                }
                            )
                        } */}
                    {/* </ul> */}
                </div>
            </Content>
            <Footer style={{ textAlign: "center" }}>

            </Footer>
        </Layout>
    );

}
export default withRouter(Vinos);
