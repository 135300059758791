import React, { useEffect, useContext, useState } from "react";
import Header from "../components/Header";

import { Layout } from "antd";
import { Table, Tag, Space, Alert } from "antd";

import { Auth } from "../context/AuthContext";
import { withRouter } from "react-router";

import firebase from "firebase/app";

import { Form, Input, Button, Select, Checkbox } from "antd";
import MenuAdmin from "../components/MenuAdmin";

import {
  denominaciones,
  grupos,
  allowedMails,
  options,
} from "../config/constantes";

const { Option } = Select;
const layout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 20,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};
const ModificaPlato = ({ history }) => {
  const { Content, Footer } = Layout;
  const { usuario } = useContext(Auth);

  const [nombre, setNombre] = useState(null);
  const [valueGrupo, setValueGrupo] = useState(null);

  const initialValue = [];
  const [carta, setCarta] = useState(initialValue);
  const [formData, updateFormData] = useState(history.location.datos);

  const [error, seterror] = useState("");

  if (history.location.datos == undefined) {
    history.push("/platos");
  }

  const { grupo, key, media, plato, publicado, racion, stock, alergenos, portada, foto } =
    history.location.datos;
  let alergenosselected = [];
  if (history.location.datos.alergenos == undefined) {
    alergenosselected = [];
  } else {
    alergenosselected = Object.keys(alergenos).filter((k) => alergenos[k]);
  }

  const handleSetPlato = (e) => {
    e.preventDefault();
    const { plato, media, racion, grupo, alergenos } = formData;

    // var post = firebase.database().ref().child('carta/grupos').child(grupo).child('platos').child(key);

    var postData = {
      nombreplato: plato,
      media: media ? media : null,
      racion: racion,
      publicado: true,
      portada: portada ? portada : false,
      foto: foto ? foto : false,
      stock: stock ? stock : null,
      alergenos: alergenos ? alergenos : null,
    };

    var updates = {};
    updates["/carta/grupos/" + grupo + "/platos/" + key] = postData;
    var post = firebase.database().ref().update(updates);

    e.target.reset();
    history.push("/platos");
  };

  const handleOnChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  const handleOnChangeGrupo = (value, e) => {
    setValueGrupo(value);
    updateFormData({
      ...formData,
      grupo: value,
    });
  };

  /*useEffect(() => {
    firebase
      .database()
      .ref()
      .child("carta/grupos")
      .once("value", (snapshot) => {
        snapshot.forEach((snap) => {
          //allcarta.push(snap.val());
        });
      })
      .then(function () {
        //setCarta(allcarta);
      });
  }, []);*/

  useEffect(() => {
    if (usuario === null) {
      history.push("/");
    }
    usuario
      ? usuario.displayName
        ? setNombre(usuario.displayName)
        : setNombre(usuario.email)
      : setNombre(null);
  }, [usuario]);

  const layoutStyle =
    window.innerWidth > 900 ? { height: "100vh" } : { height: "auto" };
  const mystyle =
    window.innerWidth > 900 ? { padding: "0px 50px" } : { padding: "0px" };
  const tableWrapper =
    window.innerWidth > 900
      ? { background: "#fff", minHeight: "80vh", padding: 24 }
      : { background: "#fff", minHeight: "80vh", padding: 10 };



  const onChange = (checkedValues) => {
    const target = {};
    const targetportada = {};

    options.forEach((key) =>
      checkedValues.includes(key.value)
        ? (target[key.value] = true)
        : (target[key.value] = false)
    );

    updateFormData({
      ...formData,
      alergenos: target,
    });
  };

  

  return (
    <Layout style={layoutStyle}>
      {/* <Header
                titulo="Modifica Plato"
                subtitulo={`Bienvenido ${nombre}`}
            /> */}
      <MenuAdmin titulo="Modifica Plato" />

      <Content style={mystyle}>
        <div style={tableWrapper}>
          <Form
            {...layout}
            id="ModificaPlato"
            name="control-hooks"
            onSubmit={handleSetPlato}
          >
            <Form.Item
              label="Plato"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                name="plato"
                defaultValue={plato}
                onChange={handleOnChange}
              />
            </Form.Item>
            <Form.Item
              name="media"
              label="Media"
              onchange={handleOnChange}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input
                defaultValue={media}
                name="media"
                onChange={handleOnChange}
              />
            </Form.Item>
            <Form.Item
              name="racion"
              label="Ración"
              onChange={handleOnChange}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                defaultValue={racion}
                name="racion"
                onChange={handleOnChange}
              />
            </Form.Item>


            

            {/* <Form.Item

                            label="Grupo"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >

                            <Select
                                name="grupo"
                                onSelect={(value, e) => handleOnChangeGrupo(value, e)}

                                placeholder="Select a option and change input text above"
                                allowClear
                            >
                                {
                                    grupos.map((grupo, i) => { return <Option value={i}>{grupo}</Option> })
                                }

                            </Select>
                        </Form.Item> */}
            

            <Form.Item label="Alérgenos">
              <Checkbox.Group
                options={options}
                defaultValue={alergenosselected}
                onChange={onChange}
              />
            </Form.Item>


<p>{portada? <Alert message="Este plato está destacado en la portada de la página" />: ''}</p>
<p>{foto ? <img src={foto} />  : ''}</p>
            




            <Form.Item {...tailLayout}>
              <Button type="submit" htmlType="submit">
                {" "}
                Enviar{" "}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Content>
      <Footer style={{ textAlign: "center" }}></Footer>
    </Layout>
  );
};
export default withRouter(ModificaPlato);
