import firebase from 'firebase/app';

const db = firebase.database().ref("/carta/grupos")

const getAll = () => {
  return db;
};

const create = (data) => {
  return db.push(data);
};

const update = (key, data) => {
  return db.child(key).update(data);
};

const remove = (key) => {
  return db.child(key).remove();
};

const removeAll = () => {
  return db.remove();
};

const decrementaStock = (grupo, idplato) => {
  const databaseRef = firebase.database().ref('/carta/grupos/' + grupo + '/platos/' + idplato);


  databaseRef.transaction((currentData) => {
    // Obtener el valor actual y el estado de "publicado"
    let currentStock = currentData.stock;
    let publicado = currentData.publicado;

    // Verificar si el stock actual es numérico
    if (typeof currentStock === 'number') {
      // Decrementar el stock en 1
      currentStock = Math.max(0, currentStock - 1);

      // Verificar si el stock llegó a 0
      if (currentStock === 0) {
        // Cambiar la propiedad "publicado" a false
        publicado = false;
      }

      // Actualizar solo la propiedad "stock" y "publicado"
      return {
        ...currentData,
        stock: currentStock,
        publicado: publicado
      };
    } else {
      // Si el stock actual no es numérico, devolver el mismo valor
      return currentData;
    }
  })
    .then((result) => {
      if (result.committed) {
        console.log('Stock decrementado exitosamente.');
        if (result.snapshot.child("publicado").val() === false) {
          console.log('La propiedad "publicado" ha sido cambiada a false.');
        }
      } else {
        console.log('No se realizó ninguna actualización.');
      }
    })
    .catch((error) => {
      console.error('Error al decrementar el stock:', error);
    });

}

export default {
  getAll,
  create,
  update,
  remove,
  removeAll,
  decrementaStock
};
