import React, { useEffect, useContext, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import FormDialog from './Modal';

import { Auth } from "../context/AuthContext";
import { denominaciones, grupos, allowedMails } from "../config/constantes"
import { useHistory } from "react-router-dom";

import { Table, Tag, Space, Input, Button, Select, Avatar, Popconfirm, message, Collapse, Modal, Drawer, Icon, Menu } from 'antd';

import firebase from 'firebase/app';
import { v4 as uuidv4 } from 'uuid';
import Moment from 'react-moment';

import ComandasDataService from "../services/ComandasService";
import CartaDataService from "../services/CartaService";

import MenuAdmin from '../components/MenuAdmin'

import { InstagramOutlined, FacebookOutlined, ToolOutlined, UserOutlined, NotificationOutlined, DeleteOutlined, EditOutlined, CloseCircleOutlined } from '@ant-design/icons';

const { Option } = Select;
const { Panel } = Collapse;
const { TextArea } = Input;
const { SubMenu } = Menu;

const useStyles = makeStyles((theme) => ({
  carta: {
    padding: "10px 20px 10px 20px",
    textAlign: "center",
    "& p": {
      textAlign: "left"
    }

  },
  cartaItems: {
    display: "flex",
    marginBottom: '5px',
    justifyContent: "space",
    fontSize: '12px',
    "& .ant-select": {
      width: "40%",
      marginRight: "10px"
    },
    "& span:first-child ": {
      display: "flex",
      justifyContent: "space-between",
      width: "60%",
      textAlign: "left",

    },
    "& span": {
      width: "20%",
    },
    "& span.ant-select-arrow": {
      width: "auto",
    }
  },

  plato: {
    display: "flex",
    justifyContent: "flex-start",
    width: "33%",
  },
  h2: {
    textAlign: "left",
    fontSize: '20px'
  },
  grupo: {
    paddingInlineStart: '0px'
  },
  textCenter: {
    textAlign: "center"
  },
  comandaWrapper: {
    border: '1px solid #eee',
    padding: '8px'
  },
  pedidoComanda: {
    textAlign: 'left',
    listStyle: 'none',
    paddingLeft: 0,
  },
  itemPedidoComanda: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '3px'
  },
  // avatar: {
  //   display: 'flex',
  //   justifyContent: 'space-between',
  //   padding: '8px',
  //   background: '#eee'
  // },
  mediaIcon: {
    width: "10px",
    height: "20px",
    backgroundColor: "white",
    borderBottomLeftRadius: "10px",
    borderTopLeftRadius: "10px",
    border: "2px solid #d9d9d9",
    display: "inline-block",
    verticalAlign: "bottom",
    marginRight: "10px"

  },
  enteraIcon: {
    width: "20px",
    height: "20px",
    backgroundColor: "white",
    borderBottomRightRadius: "10px",
    borderTopRightRadius: "10px",
    borderBottomLeftRadius: "10px",
    borderTopLeftRadius: "10px",
    border: "2px solid #d9d9d9",
    display: "inline-block",
    verticalAlign: "bottom"

  },
  platocomanda: {
    flex: "1 1 auto"
  },
  editcomanda: {
    marginRight: "3px"
  },
  // listmenu: {
  //   listStyleType: "none",
  //   paddingLeft: "0px"
  // },
  // drawer: {
  //   padding: "0px",
  //   "& .ant-drawer-body": {
  //     padding: "0px"
  //   },
  //   "& .ant-menu-inline": {
  //     border: "none"
  //   }
  // },

}));

const Comanda = ({ history }) => {

  const classes = useStyles();


  const { usuario } = useContext(Auth);
  const [nombre, setNombre] = useState(null)

  const colorestags = ["", "magenta", "red", "volcano", "orange", "gold", "lime", "green", "cyan", "blue", "geekblue", "purple"]

  const initialValue = []
  const [carta, setCarta] = useState(initialValue);
  const [cartaVinos, setCartaVinos] = useState(initialValue);

  const [mesa, setMesa] = useState(initialValue);
  const [comanda, setComanda] = useState(initialValue);

  const onDataChange = (items) => {
    let cartaAux = [];

    items.forEach((item) => {
      let key = item.key;
      let data = item.val();
      cartaAux.push({
        key: key,
        platos: data.platos,
        grupo: data.nombre,
      });
    });

    setCarta(cartaAux);
  };

  useEffect(() => {
    CartaDataService.getAll().on("value", onDataChange);

    return () => {
      CartaDataService.getAll().off("value", onDataChange);
    };
  }, []);

  const anadirPlato = (datos, cantidad, mesa, grupo, idplato) => {
    // console.log("datos", datos)
    // console.log("grupo", grupo)
    if (mesa.length == 0) {
      message.error("Debe seleccionar antes una mesa");
    } else {
      let hora = Date.now();
      let comandaItem = {
        idItemComanda: uuidv4(),
        cantidad: cantidad.toUpperCase(),
        plato: datos.nombreplato,
        key: idplato,
        grupo: grupo,
        mesa: mesa,
        fechayhora: hora,
        comentario: datos.comentario,
        idplato: idplato
      }

      setComanda([...comanda, comandaItem])
    }
  }

  const quitarPlato = (pedido) => {
    console.log("quitar", pedido);
    console.log("comanda antes", comanda);

    // Actualiza la comanda eliminando el pedido
    setComanda((comandaActual) => {
      return comandaActual.filter((item) => item.idItemComanda !== pedido.idItemComanda);
    });

    console.log("comanda despues", comanda);
  };
  const enviarACocina = (datos) => {
    datos.map(item => {
      var data = {
        plato: item.plato,
        cantidad: item.cantidad,
        mesa: item.mesa,
        idItemComanda: item.idItemComanda,
        grupo: item.grupo,
        fechayhora: item.fechayhora,
        comentario: item.comentario ? item.comentario : '',
        hecho: false,
        idplato: item.idplato
      };

      ComandasDataService.create(data)
        .then(() => {
          message.success('Oido cocina ' + data.cantidad + ' de ' + data.plato);
          //   console.log("data", data)
          quitaPlatoStock(data.idplato, data.grupo)


        })
        .catch(e => {
          console.log(e);
        });

    })
    setComanda(initialValue)

  }

  const quitaPlatoStock = (key, grupo) => {


    CartaDataService.decrementaStock(grupo, key)

  }


  const [isModalVisible, setIsModalVisible] = useState(false);
  // const [visible, setVisible] = useState(false);
  const [comentario, setComentario] = useState(initialValue);

  const showModal = () => {
    setIsModalVisible(true);
  };


  const handleOk = (pedido) => {
    let comandaActualizada = comanda.map((item) => {

      if (item.idItemComanda === pedido.idItemComanda) {
        return {
          ...item,
          cantidad: pedido.cantidad,
          nombreplato: pedido.plato + "***",
          comentario: comentario,
        };
      }
      return item;
    });
    setComanda(comandaActualizada);

    setIsModalVisible(false);
  };


  const cambiarComentario = (comentario) => {
    setComentario(comentario)
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  // const showDrawer = () => {
  //   setVisible(true)
  // };

  // const onClose = () => {
  //   setVisible(false)
  // };

  let allcarta = [];
  let allcartavinos = [];
  let dataSource = [];
  let dataSourceVinos = []
  let aux

  return (
    <>

      <MenuAdmin titulo="Nueva comanda" />

      <div className={classes.carta}>

        {comanda.length > 0 &&
          <>
            <div className={classes.comandaWrapper}>
              <ul className={classes.pedidoComanda}>
                {
                  comanda.map(pedido => {
                    return <li key={pedido.plato}>
                      <div className={classes.itemPedidoComanda}><div className={classes.platocomanda}>{pedido.cantidad == 'MEDIA' ? <div className={classes.mediaIcon}></div> : <div className={classes.enteraIcon}></div>} {pedido.plato}</div>
                        <div className={classes.editcomanda}><Button size="small" onClick={showModal} type="default"><EditOutlined /></Button>
                          <Modal title={pedido.plato} visible={isModalVisible} onOk={(e) => handleOk(pedido)} onCancel={handleCancel}>
                            <TextArea onChange={(e) => { cambiarComentario(e.target.value) }} autoSize={{ minRows: 3, maxRows: 5 }} />
                          </Modal></div>
                        <div><Button size="small" onClick={(e) => { quitarPlato(pedido) }} type="default"><DeleteOutlined /></Button></div>
                      </div></li>
                  })
                }
              </ul>
              {comanda.length > 0 ? <Button onClick={(e) => { enviarACocina(comanda) }} type="default"><NotificationOutlined />Enviar a cocina</Button> : ""}
            </div>
            <hr />


          </>
        }
        <span className={classes.cartaItems}>
          <Select onChange={(value) => {
            setComanda(initialValue)
            setMesa(value)
          }} value={mesa}>
            <Option value="0">Barra</Option>
            <Option value="1">Mesa 1</Option>
            <Option value="2">Mesa 2</Option>
            <Option value="3">Mesa 3</Option>
            <Option value="4">Mesa 4</Option>
            <Option value="5">Mesa 5</Option>
            <Option value="6">Mesa 6</Option>
            <Option value="7">Mesa 7</Option>
            <Option value="8">Mesa 8</Option>
            <Option value="9">Mesa 9</Option>
            <Option value="10">Mesa 10</Option>
            <Option value="11">Mesa 11</Option>
            <Option value="12">Mesa 12</Option>
            <Option value="13">Mesa 13</Option>
            <Option value="14">Mesa 14</Option>
            <Option value="15">Mesa 15</Option>
            <Option value="16">Mesa 16</Option>
            <Option value="17">Mesa 17</Option>
            <Option value="18">Mesa 18</Option>
            <Option value="19">Mesa 19</Option>
            <Option value="20">Mesa 20</Option>
          </Select>
          <span><Button onClick={(e) => { setComanda(initialValue); setMesa(initialValue) }}>Reiniciar mesas</Button></span></span>



        {/* {usuario && ( usuario.email==='juancalero@gmail.com' || usuario.email==='carloscandelariovazquez@gmail.com') && */}
        <>
          <hr />
          <span className={classes.cartaItems}><span></span><span>Media</span><span>Ración</span></span>
          {usuario && allowedMails.includes(usuario.email) && <>

            <Collapse>
              {carta.map((datos, idx) => {
                if (idx != 10 && datos.platos != null)
                  return (
                    <Panel header={datos.grupo} key={idx} >
                      {
                        Object.values(datos.platos).map((itemplato, idxplato) => {
                          // console.log("itemplato",itemplato)
                          //console.log("idx", idx)
                          //console.log("itemplato", itemplato)
                          //console.log("OBJ K", Object.keys(datos.platos))
                          //console.log("OBJ V", Object.values(datos.platos))
                          //console.log("OBJ e", Object.entries(datos.platos))
                          //console.log(itemplato.nombreplato);
                          //console.log("ID ", Object.entries(datos.platos)[idxplato][0]);
                          //console.log("datos", Object.entries(datos.platos)[idxplato][1]);

                          if (itemplato.publicado) return <div key={Object.entries(datos.platos)[idxplato][0]} className={classes.cartaItems}><span>{itemplato.nombreplato} {itemplato.foto && <FormDialog key={itemplato.foto} value={datos.foto} plato={itemplato.plato} />} </span><span>{itemplato.media && <Button key={itemplato.nombreplato} onClick={(e) => { anadirPlato(itemplato, 'media', mesa, idx + 1, Object.entries(datos.platos)[idxplato][0]); }} type="default">{itemplato.media}</Button>}</span><span><Button key={itemplato.nombreplato} onClick={(e) => { anadirPlato(itemplato, 'racion', mesa, idx + 1, Object.entries(datos.platos)[idxplato][0]); }} type="default">{itemplato.racion}</Button></span></div>
                        })


                      }
                    </Panel>)
              })
              }
            </Collapse>

          </>
          }

        </>
      </div>
    </>
  );

}
export default Comanda;

