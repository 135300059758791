import React, { useEffect, useContext, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';

import { WhatsAppOutlined, PhoneOutlined } from '@ant-design/icons';

import HorariosDataService from "../services/HorariosService";

import firebase from 'firebase/app';


const useStyles = makeStyles((theme) => ({
  titulo: {
      fontStyle: "normal",
      fontFamily: "Roboto Slab",
      color: "#fff",
      paddingLeft: "25px",
      paddingTop: "15px",
      fontSize: "2em"

      
  
  },
  contenedor:{
      background: "#2f3840",
      paddingBottom: "25px"
  },
  texto:{
      color: "#baa470",
      float: "left",
      width: "35%",
      paddingLeft: "20px",
      fontSize: "16px"
  }
}));

function Container() {

  const classes = useStyles();

  const initialValue = []
  const [horarios, setHorarios] = useState(initialValue);

  const onDataChange = (items) => {
    let horariosAux = [];

        items.forEach((item) => {         

          let data = item.val();
          horariosAux.push({
            dia: data.dia,
            horariodia: data.horariodia
          });
        });
        //horariosAux.sort((a, b) => (a.grupo > b.grupo) ? 1 : -1)
    
    
        setHorarios(horariosAux);
  };

  useEffect(() => {
    HorariosDataService.getAll().on("value", onDataChange);

    return () => {
      HorariosDataService.getAll().off("value", onDataChange);
    };
  }, []);


  return (
    <div className={classes.contenedor}>
    <div className="container">
      <div className="row-flex flex-wrap w-100">
        <div className="flex-column-form reservations">
          <h3 className="titulo3" id="reservas">
            Reservas
                </h3>
          <form className="media-centered">
            <div className="form-group">
              <p>Puedes contactar con nosotros para hacer tu reserva en:</p>
              <p>
                  <a href="tel:675025494"><PhoneOutlined /> 675 02 54 94</a>
              </p>
              <p>
                <a href="https://wa.me/+34675025494"><WhatsAppOutlined /> WhatsApp</a>
              </p>
              {/* <input type="name" className="form-control" id="exampleInputName1" aria-describedby="nameHelp" placeholder="Introduce tu nombre" /> */}
            </div>
            {/* <div className="form-group">
              <input type="number" className="form-control" id="exampleInputphoneNumber1" placeholder="Introduce tu número de teléfono" />
            </div>
            <button type="submit" className="btn btn-primary">Enviar</button> */}
          </form>
        </div>
        <div className="opening-time">
          <h3 className="titulo3" id="horario">Horario
                </h3>
          <p>

            {
              horarios && horarios.map( (item) => {
                return <span><strong>{item.dia}</strong> {item.horariodia}</span>

              })
            }

          </p>
        </div>
        <div className="contact-adress">
          <h3 className="titulo3" id="direccion">
            Dirección
                </h3>
          <p>
            <span>Avenida Gran Capitán 35, Córdoba </span>
          </p>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3542.123408264639!2d-4.7852743678965695!3d37.88730409580539!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa8a01804281497a2!2sEl%20Lindero%20Taberna!5e0!3m2!1ses!2ses!4v1659418162286!5m2!1ses!2ses"  style={{border:0, width:'100%' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
    </div>
    </div>
  );

}
export default Container;