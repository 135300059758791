import React from 'react';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    titulo: {
        textShadow: "0.125rem 0.125rem 0 #000 !important",
        fontStyle: "normal",
        fontFamily: "Roboto Slab"

        
    
    }
}));

function Intro() {
  
  const classes = useStyles();

  return (
    <>
        <section className="section-intro">
        <header>
            <h1 className={classes.titulo}>El Lindero <br />Gastrotaberna</h1>
        </header>
        {/* <div class="link-to-book-wrapper">
            <a class="link-to-book" href="#reservations">Book a table</a>
        </div> */}
        </section>
    </>
);

}
export default Intro;



