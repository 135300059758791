import React, { useEffect, useContext, useState } from "react";
import Header from "../components/Header";

import { Layout } from "antd";
import { Table, Tag, Space } from 'antd';

import { Auth } from "../context/AuthContext";
import { withRouter } from "react-router";

import firebase from 'firebase/app';

import { Form, Input, Button, Select } from 'antd';
import { denominaciones } from "../config/constantes"
import MenuAdmin from '../components/MenuAdmin'


const { Option } = Select;
const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 20,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};
const ModificaVino = ({ history }) => {

    const { Content, Footer } = Layout;
    const { usuario } = useContext(Auth);

    const [nombre, setNombre] = useState(null);
    const [valueGrupo, setValueGrupo] = useState(null)

    const initialValue = []
    const [carta, setCarta] = useState(initialValue);
    const [formData, updateFormData] = useState(history.location.datos);

    const [error, seterror] = useState("");

    const { origen, anyo, uva, envejecimiento, vino, publicado, bodega, precio, orden } = history.location.datos
    const key = history.location.idvino


    const handleSetVino = (e) => {
        e.preventDefault();
        const { vino, anyo, envejecimiento, bodega, uva, origen, precio, orden } = formData;

        // var post = firebase.database().ref().child('carta/grupos').child(grupo).child('platos').child(key);


        var postData = {
            origen: origen,
            vino: vino,
            anyo: anyo,
            envejecimiento: envejecimiento,
            bodega: bodega,
            precio: precio,
            orden: orden,
            uva: uva,
            publicado: false
        }

        var updates = {};
        updates['/vinos/' + key] = postData;
        console.log("updates", updates)
        var post = firebase.database().ref().update(updates);

        e.target.reset();
        history.push("/vinos");

    };



    const handleOnChange = (e) => {
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value.trim()
        })
    }

    const handleOnChangeGrupo = (value, e) => {
        setValueGrupo(value)
        updateFormData({
            ...formData,
            origen: value,
        })
    }



    useEffect(() => {
        firebase.database().ref().child('vinos').once("value", snapshot => {
            snapshot.forEach(snap => {
                //allcarta.push(snap.val());
            })
        })
            .then(function () {
                //setCarta(allcarta);
            })

    }, [])

    useEffect(() => {

        if (usuario === null) {
            history.push("/");
        }
        usuario ? usuario.displayName ? setNombre(usuario.displayName) : setNombre(usuario.email) : setNombre(null)

    }, [usuario]);



    const layoutStyle = (window.innerWidth > 900) ? { height: "100vh" } : { height: "auto" }
    const mystyle = (window.innerWidth > 900) ? { padding: "0px 50px" } : { padding: "0px" }
    const tableWrapper = (window.innerWidth > 900) ? { background: "#fff", minHeight: "80vh", padding: 24 } : { background: "#fff", minHeight: "80vh", padding: 10 }


    return (
        <Layout style={layoutStyle}>
            {/* <Header
                titulo="Modifica Plato"
                subtitulo={`Bienvenido ${nombre}`}
            /> */}
            <MenuAdmin titulo="Modifica Vino" />

            <Content style={mystyle}>
                <div style={tableWrapper}>

                    <Form {...layout} id="ModificaVino" name="control-hooks" onSubmit={handleSetVino} >
                        <Form.Item
                            label="Vino"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input
                                name="vino"
                                defaultValue={vino}
                                onChange={handleOnChange}
                            />
                        </Form.Item>
                        <Form.Item
                            name="anyo"
                            label="Año"
                            onchange={handleOnChange}
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input
                                defaultValue={anyo}
                                name="anyo"
                                onChange={handleOnChange}
                            />
                        </Form.Item>
                        <Form.Item
                            name="uva"
                            label="Uva"
                            onchange={handleOnChange}
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input
                                defaultValue={uva}
                                name="uva"
                                onChange={handleOnChange}
                            />
                        </Form.Item>

                        <Form.Item
                            name="envejecimiento"
                            label="Envejecimiento"
                            onchange={handleOnChange}
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input
                                defaultValue={envejecimiento}
                                name="envejecimiento"
                                onChange={handleOnChange}
                            />
                        </Form.Item>
                        <Form.Item
                            name="bodega"
                            label="Bodega"
                            onChange={handleOnChange}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input
                                defaultValue={bodega}
                                name="bodega"
                                onChange={handleOnChange}
                            />
                        </Form.Item>
                        <Form.Item
                            name="precio"
                            label="Precio"
                            onChange={handleOnChange}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input
                                defaultValue={precio}
                                name="precio"
                                onChange={handleOnChange}
                            />
                        </Form.Item>
                        <Form.Item

                            label="Denominacion de Origen"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >

                            <Select
                                name="origen"
                                onSelect={(value, e) => handleOnChangeGrupo(value, e)}
                                defaultValue={origen}
                                placeholder="Select a option and change input text above"
                                allowClear
                            >
                                {
                                    denominaciones.map((deno, i) => { return <Option value={i}>{deno}</Option> })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => prevValues.gender !== currentValues.gender}
                        >
                            {({ getFieldValue }) =>
                                getFieldValue('grupo') === 'other' ? (
                                    <Form.Item
                                        name="customizeGender"
                                        label="Customize Gender"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                ) : null
                            }
                        </Form.Item>
                        <Form.Item
                            name="orden"
                            label="Orden"
                            onchange={handleOnChange}
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input
                                defaultValue={orden}
                                name="orden"
                                onChange={handleOnChange}
                            />
                        </Form.Item>
                        
                        <Form.Item {...tailLayout}>
                            <Button type="submit" htmlType="submit"> Submit </Button>
                        </Form.Item>
                    </Form>
                </div>

            </Content>
            <Footer style={{ textAlign: "center" }}>

            </Footer>
        </Layout>
    );

}
export default withRouter(ModificaVino);
