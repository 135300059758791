import React, { useEffect, useContext, useState } from "react";
import Header from "../components/Header";

import { Layout, Table, Tag, Space, Checkbox, Form, Input, Button, Select } from 'antd';

import { Auth } from "../context/AuthContext";
import { withRouter } from "react-router";

import firebase from 'firebase/app';
import { denominaciones, grupos, allowedMails, options } from "../config/constantes"
import MenuAdmin from '../components/MenuAdmin'


const { Option } = Select;
const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 20,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};
const AltaPlato = ({ history, props }) => {
    const { Content, Footer } = Layout;
    const { usuario } = useContext(Auth);

    const [nombre, setNombre] = useState(null);
    const [valueGrupo, setValueGrupo] = useState(null)

    const initialValue = []
    const [carta, setCarta] = useState(initialValue);
    const [formData, updateFormData] = useState({});


    const [error, seterror] = useState("");

    const handleSetPlato = (e) => {
        e.preventDefault();
        const { plato, media, racion, grupo, alergenos } = formData;

        var post = firebase.database().ref().child('carta/grupos').child(grupo).child('platos');
        var newpost = post.push();
        newpost.set({
            nombreplato: plato,
            media: media ? media : null,
            racion: racion,
            publicado: true,
            stock: null,
            alergenos:alergenos?alergenos:null
        })
        e.target.reset();
        history.push("/platos");

    };



    const handleOnChange = (e) => {
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value.trim()
        })
    }

    const handleOnChangeGrupo = (value, e) => {
        setValueGrupo(value)
        updateFormData({
            ...formData,
            grupo: value,
        })
    }

    useEffect(() => {
        firebase.database().ref().child('carta/grupos').once("value", snapshot => {
            snapshot.forEach(snap => {
                //allcarta.push(snap.val());
            })
        })
            .then(function () {
                //setCarta(allcarta);
            })

    }, [])

    useEffect(() => {
        //let allowedMails = ['juancalero@gmail.com', 'carloscandelariovazquez@gmail.com']
        if (usuario === null || !allowedMails.includes(usuario.email)) {
            history.push("/");
        }
        usuario ? usuario.displayName ? setNombre(usuario.displayName) : setNombre(usuario.email) : setNombre(null)
    }, [usuario]);




    const mystyle = (window.innerWidth > 900) ? { padding: "0px 50px" } : { padding: "0px" }
    const tableWrapper = (window.innerWidth > 900) ? { background: "#fff", minHeight: "80vh", padding: 24 } : { background: "#fff", minHeight: "80vh", padding: 10 }

    // const options = [
    //     { label: 'Cacahuete', value: 'cacahuete' },
    //     { label: 'Apio', value: 'apio' },
    //     { label: 'Altramuces', value: 'altramuces' },
    //     { label: 'Frutos de cáscara', value: 'cascara' },
    //     { label: 'Crustáceos', value: 'crustaceos' },
    //     { label: 'Gluten', value: 'gluten' },
    //     { label: 'Huevo', value: 'huevo' },
    //     { label: 'Lácteos', value: 'lacteos' },
    //     { label: 'Moluscos', value: 'molusco' },
    //     { label: 'Mostaza', value: 'mostaza' },
    //     { label: 'Pescado', value: 'pescado' },
    //     { label: 'Semillas de sésamo', value: 'sesamo' },
    //     { label: 'Soja', value: 'soja' },
    //     { label: 'Sulfitos', value: 'sulfitos' },
    // ];


    const onChange = (checkedValues) => {
        const target = {}; 
        options.forEach(key => checkedValues.includes(key.value) ? target[key.value] = true : target[key.value] = false);

        updateFormData({
            ...formData,
            alergenos: target
        })
    }

    return (
        <Layout style={{ height: "100vh" }}>
            {/* <Header
                titulo="Añadir Plato"
                subtitulo={`Bienvenido ${nombre}`}
            /> */}
            
      <MenuAdmin titulo="Alta Plato"/>
            <Content style={mystyle}>
                <div style={tableWrapper}>
                    <Form {...layout} id="altaplato" name="control-hooks" onSubmit={handleSetPlato} >
                        <Form.Item
                            label="Plato"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input
                                name="plato"
                                onChange={handleOnChange}
                            />
                        </Form.Item>
                        <Form.Item
                            name="media"
                            label="Media"
                            onchange={handleOnChange}
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input
                                name="media"
                                onChange={handleOnChange}
                            />
                        </Form.Item>
                        <Form.Item
                            name="racion"
                            label="Ración"
                            onChange={handleOnChange}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input
                                name="racion"
                                onChange={handleOnChange}
                            />
                        </Form.Item>

                        <Form.Item

                            label="Grupo"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            {/* <Select
                                name="grupo"
                                onSelect={(value, e) => handleOnChangeGrupo(value, e)}

                                placeholder="Select a option and change input text above"
                                allowClear
                            >
                                <Option value="1">Comenzamos</Option>
                                <Option value="2">Ensaladas y entrantes</Option>
                                <Option value="3">Revueltos y verduras</Option>
                                <Option value="4">Fritos y caseros</Option>
                                <Option value="5">Pescados</Option>
                                <Option value="6">Carnes</Option>
                                <Option value="7">Sugerencias</Option>
                                <Option value="8">Postres</Option>
                                <Option value="9">Bebidas</Option>

                                
                            </Select> */}

                            <Select
                                name="grupo"
                                onSelect={(value, e) => handleOnChangeGrupo(value, e)}

                                placeholder="Select a option and change input text above"
                                allowClear
                            >
                                {
                                    grupos.map((grupo, i) => { return <Option value={i}>{grupo}</Option> })
                                }

                            </Select>
                        </Form.Item>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => prevValues.gender !== currentValues.gender}
                        >
                            {({ getFieldValue }) =>
                                getFieldValue('grupo') === 'other' ? (
                                    <Form.Item
                                        name="customizeGender"
                                        label="Customize Gender"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                ) : null
                            }
                        </Form.Item>

                        <Form.Item
                        label="Alérgenos"
                        >
                        <Checkbox.Group options={options} defaultValue={['']} onChange={onChange} />

                        </Form.Item>
                        <Form.Item {...tailLayout}>
                            <Button type="submit" htmlType="submit"> Submit </Button>
                        </Form.Item>
                    </Form>
                </div>

            </Content>
            <Footer style={{ textAlign: "center" }}>

            </Footer>
        </Layout>
    );

}
export default withRouter(AltaPlato);
