import React, { useEffect, useContext, useState } from "react";
import Header from "../components/Header";
import { useHistory } from "react-router-dom";

import Resizer from "react-image-file-resizer";

import { Layout } from "antd";
import {
  Table,
  Tag,
  Space,
  Button,
  message,
  Switch,
  Modal,
  Upload,
  Alert,
  Input
} from "antd";

import { Auth } from "../context/AuthContext";
import { withRouter } from "react-router";

import firebase from "firebase/app";

import {
  CheckOutlined,
  CloseOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  EditOutlined,
  EyeTwoTone,
  EyeInvisibleTwoTone,
  DeleteOutlined,
  StarFilled,
  PlusCircleTwoTone,
  MinusCircleTwoTone,
  CameraOutlined,
  CameraTwoTone,
  PlusOutlined,
  StarOutlined,
  SearchOutlined,
  StarTwoTone,
} from "@ant-design/icons";
import FormDialog from "./Modal";

import { grupos } from "../config/constantes";
import { allowedMails } from "../config/constantes";
import MenuAdmin from "../components/MenuAdmin";
import { FormatListBulletedSharp } from "@material-ui/icons";

const Dashboard = ({ history }) => {
  const { Content, Footer } = Layout;
  const { usuario } = useContext(Auth);
  const [nombre, setNombre] = useState(null);

  const initialValue = [];
  const [carta, setCarta] = useState(initialValue);

  let allcarta = [];

  let dataSource = [];
  
  const [data, setData] = useState(null );
  const [value, setValue] = useState('');


  const allInputs = { imgUrl: "" };
  const [imageAsFile, setImageAsFile] = useState("");
  const [imageAsUrl, setImageAsUrl] = useState(allInputs);

  


  const columns = [
    {
      title: "",
      key: "publicacion",
      responsive: ["lg"],
      render: (data) => {
        if (
          typeof data !== "undefined" &&
          typeof data.publicado !== "undefined" &&
          data.publicado === true
        ) {
          if(data.portada === true){
            return (
              <>
                <StarFilled style={{color: "yellow"}} />{" "}
              </>
            );
          }
          else{

          
          return (
            <>
              <CheckCircleTwoTone twoToneColor="#52c41a" />{" "}
            </>
          );
          }
        } else
          return (
            <>
              <CloseCircleTwoTone twoToneColor="#eb2f96" />{" "}
            </>
          );
      },
    },
    {
      title: "Plato",
      dataIndex: "plato",
      key: "plato",
      sorter: (a, b) => a.plato.localeCompare(b.plato),
      sortDirections: ["descend", "ascend"],
      render: (data) => {
        return <> {data} </>;
      },
    },
    {
      title: "Grupo",
      key: "grupo",
      dataIndex: "grupo",
      filters: [
        {
          text: "Comenzamos",
          value: 1,
        },
        {
          text: "Ensaladas y entrantes",
          value: 2,
        },
        {
          text: "Revueltos y verduras",
          value: 3,
        },
        {
          text: "Fritos y caseros",
          value: 4,
        },
        {
          text: "Pescados",
          value: 5,
        },
        {
          text: "A la parrilla",
          value: 6,
        },
        {
          text: "Sugerencias",
          value: 7,
        },
        {
          text: "Postres",
          value: 8,
        },
        {
          text: "Bebidas",
          value: 9,
        },
        {
          text: "Fuera de Carta",
          value: 10,
        },
      ],
      onFilter: (value, data) => data.grupo == value,
      sorter: (a, b) => a.grupo.toString().localeCompare(b.grupo),
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
      render: (data) => {
        return <>{grupos[data]}</>;
      },
    }
    
  ];

  carta.length > 0 &&
    carta.map((item, i) => {
      item.platos != null &&
        Object.keys(item.platos).length > 0 &&
        Object.values(item.platos).map((plato, j) => {
          let keys = Object.keys(item.platos);

          dataSource.push({
            key: keys[j],
            grupo: i + 1,
            plato: plato.nombreplato,
            media: plato.media,
            racion: plato.racion,
            publicado: plato.publicado,
            portada: plato.portada,
            alergenos: plato.alergenos,
            stock: plato.stock,
            foto: plato.foto,
          });
        });
    });

  useEffect(() => {
    const datos = firebase.database().ref().child("carta/grupos");
    datos
      .once("value", (snapshot) => {
        snapshot.forEach((snap) => {
          allcarta.push(snap.val());
        });
      })
      .then(function () {
        setCarta(allcarta);
      });
  }, []);

  useEffect(() => {
    if (usuario === null || !allowedMails.includes(usuario.email)) {
      history.push("/");
    }
    usuario
      ? usuario.displayName
        ? setNombre(usuario.displayName)
        : setNombre(usuario.email)
      : setNombre(null);
  }, [usuario]);

  const loadPlato = (data) => {
    history.push({
      pathname: "/modificaplato",
      datos: data,
    });
  };

  const cambiarPublicacion = (data, e) => {
    e.preventDefault();
    if (data.publicado === undefined) data.publicado = false;
    var postData = {
      nombreplato: data.plato,
      media: data.media ? data.media : null,
      racion: data.racion,
      publicado: !data.publicado,
      portada: data.portada ? data.portada : false,
      alergenos: data.alergenos ? data.alergenos : null,
      stock: data.stock ? data.stock : null,
      foto: data.foto ? data.foto : null,
    };

    var updates = {};
    updates["/carta/grupos/" + data.grupo + "/platos/" + data.key] = postData;
    var post = firebase.database().ref().update(updates);
    data.publicado
      ? message.info(data.plato + " se ha despublicado")
      : message.info(data.plato + " se ha publicado");

    //e.target.reset();
  };

  const cambiarPromocionado = (data, e) => {
    e.preventDefault();
    if (data.portada === undefined) data.portada = false;
    var postData = {
      nombreplato: data.plato,
      media: data.media ? data.media : null,
      racion: data.racion,
      publicado: data.publicado,
      portada: !data.portada,
      alergenos: data.alergenos ? data.alergenos : null,
      stock: data.stock ? data.stock : null,
      foto: data.foto ? data.foto : null,
    };

    var updates = {};
    updates["/carta/grupos/" + data.grupo + "/platos/" + data.key] = postData;
    var post = firebase.database().ref().update(updates);
    data.portada
      ? message.info(data.plato + " se ha quitado de portada")
      : message.info(data.plato + " se ha promocionado a portada");

    //e.target.reset();
  };

  const borrarPlato = (data, e) => {
    e.preventDefault();

    var post = firebase
      .database()
      .ref("/carta/grupos/" + data.grupo + "/platos/" + data.key)
      .remove();

    //e.target.reset();
  };

  const handleImageAsFile = (e, data) => {
    e.preventDefault();
    const image = e.target.files[0];
    setImageAsFile((imageAsFile) => image);

  };

  const handleFireBaseUpload = (e, data) => {
    e.preventDefault();
    console.log("start of upload");
    // async magic goes here...
    if (imageAsFile === "") {
      console.error(`not an image, the image file is a ${typeof imageAsFile}`);
    }
    const uploadTask = firebase
      .storage()
      .ref(`/images/${imageAsFile.name}`)
      .put(imageAsFile);
    //initiates the firebase side uploading
    uploadTask.on(
      "state_changed",
      (snapShot) => {
        //takes a snap shot of the process as it is happening
        console.log(snapShot);
      },
      (err) => {
        //catches the errors
        console.log(err);
      },
      () => {
        // gets the functions from storage refences the image storage in firebase by the children
        // gets the download url then sets the image from firebase as the value for the imgUrl key:
        firebase
          .storage()
          .ref("images")
          .child(imageAsFile.name)
          .getDownloadURL()
          .then((fireBaseUrl) => {
            console.log(fireBaseUrl);
            console.log(data);

            var postData = {
              nombreplato: data.plato,
              media: data.media ? data.media : null,
              racion: data.racion,
              publicado: data.publicado,
              portada: data.portada ? data.portada : false,
              alergenos: data.alergenos ? data.alergenos : null,
              stock: data.stock ? data.stock : null,
              foto: fireBaseUrl ? fireBaseUrl : null,
            };

            var updates = {};
            updates["/carta/grupos/" + data.grupo + "/platos/" + data.key] =
              postData;
            var post = firebase.database().ref().update(updates);
            message.info("Foto asociada");

            setImageAsUrl((prevObject) => ({
              ...prevObject,
              imgUrl: fireBaseUrl,
            }));
          });
      }
    );
  };

  const borrarFoto = (data, e) => {
    e.preventDefault();
    if (data.publicado === undefined) data.publicado = false;
    var postData = {
      nombreplato: data.plato,
      media: data.media ? data.media : null,
      racion: data.racion,
      publicado: data.publicado,
      portada: data.portada ? data.portada : false,
      alergenos: data.alergenos ? data.alergenos : null,
      stock: data.stock ? data.stock : null,
      foto: null,
    };

    var updates = {};
    updates["/carta/grupos/" + data.grupo + "/platos/" + data.key] = postData;
    var post = firebase.database().ref().update(updates);
    data.publicado
      ? message.info(data.plato + " se ha despublicado")
      : message.info(data.plato + " se ha publicado");

    //e.target.reset();
  };

  const [antPics, setAntPics] = useState([]);
  const [loading, setLoading] = useState(false);
  const [urls, setUrls] = useState([]);

  const handleAnt = async e => {
    console.log("antes",e.file.originFileObj);
    const resizedImg =  await resizeFile(e.file.originFileObj);
    console.log("despues",resizedImg);

    setAntPics(resizedImg);
  };

  const sendAnt = (data) => {
    setLoading(true);
    console.log("uploading...",data);

    firebase
      .storage()
      .ref("images/" + antPics.name)
      .put(antPics)
      .then((snapshot) => {
        return snapshot.ref.getDownloadURL();
      })
      .then((url) => {
        console.log("url",url);
        setUrls([...urls, url]);

        var postData = {
          nombreplato: data.plato,
          media: data.media ? data.media : null,
          racion: data.racion,
          publicado: data.publicado,
          portada: data.portada ? data.portada : false,
          alergenos: data.alergenos ? data.alergenos : null,
          stock: data.stock ? data.stock : null,
          foto: url ? url : null,
        };

        var updates = {};
        updates["/carta/grupos/" + data.grupo + "/platos/" + data.key] =
          postData;
        var post = firebase.database().ref().update(updates);
        message.info("Foto asociada");

        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });



  const [checked, setChecked] = useState(false);

  const handleClickDisabled = () => {

    if (!checked) {
      setChecked(true);
      var elems = document.getElementsByClassName("despublicado");
      for (var i = 0; i < elems.length; i += 1) {
        elems[i].style.display = "none";
      }
    } else {
      setChecked(false);
      var elems = document.getElementsByClassName("despublicado");

      for (var i = 0; i < elems.length; i += 1) {
        elems[i].style.display = "table-row";
      }
    }
  };

  const [checked2, setChecked2] = useState(false);

  const handleClickPromoted = () => {

    if (!checked2) {
      setChecked2(true);
      var elems = document.getElementsByClassName("nodestacado");

      for (var i = 0; i < elems.length; i += 1) {
        elems[i].style.display = "none";
      }
    } else {
      setChecked2(false);
      var elems = document.getElementsByClassName("nodestacado");

      for (var i = 0; i < elems.length; i += 1) {
        elems[i].style.display = "table-row";
      }
    }
  };

  const filtraNombre = (e) => {
    console.log("e",e.target.value)
    
  }




  const mystyle = (window.innerWidth > 900) ? { padding: "0px 50px", marginTop: 40 } : { padding: "0px", marginTop: 10 }

    const tableWrapper =
    window.innerWidth > 900
      ? { background: "#fff", minHeight: "80vh", padding: 24 }
      : { background: "#fff", minHeight: "80vh", padding: 0 };
  ///  console.log("carta",carta);

  const botonpublicar = (record) => (
    <>
     
    {(typeof record !== "undefined" && typeof record.publicado !== "undefined" && record.publicado === true) ? 
        <>
          <div style={{  marginLeft:"5px",marginBottom:"5px"}}>
          <Button
            shape="circle"
            danger
            type="default"
            onClick={(e) => {
              cambiarPublicacion(record, e);
            }}
          >
            <EyeInvisibleTwoTone twoToneColor="#eb2f96" />
          </Button> <span>Despublicar</span>
          </div>
        </>
      : 
        <>
                  <div style={{  marginLeft:"5px",marginBottom:"5px"}}>

          <Button
            shape="circle"
            type="default"
            onClick={(e) => {
              cambiarPublicacion(record, e);
            }}
          >
            <EyeTwoTone twoToneColor="#52c41a" />
            
          </Button> <span>Publicar</span>
          </div>
        </>}
    </>
  );
  const botoneditar = (record) =>(
    <><div style={{  marginLeft:"5px",marginBottom:"5px"}}><Button
          onClick={() => {
            loadPlato(record);
          }}
          shape="circle"
          key={record.plato}
        >
          <EditOutlined />
        </Button> <span>Editar</span>
        </div>
        </>
  );
  const botonborrar = (record) =>(

<>    {  (record.publicado === false) ?

    <>
            <div style={{   marginLeft:"5px",marginBottom:"5px"}}>

    <Button
              onClick={(e) => {
                borrarPlato(record, e);
              }}
              shape="circle"
              key={record.plato}
            >
              <DeleteOutlined />
            </Button> <span>Borrar</span></div></>
            :<></>
}
  </>
  );


  const botonportada = (record) =>(
    
    <>
    { 
    (typeof record !== "undefined") ? 
        ( (record.grupo >0) ? 
            ( (record.publicado == true) ?     
                ((record.portada == true) ? 
                    <>
                            <div style={{  marginLeft:"5px",marginBottom:"5px" }}>

                    <Button
                    shape="circle"
                    danger
                    type="default"
                    onClick={(e) => {
                        cambiarPromocionado(record, e);
                    }}
                    >
                      <StarOutlined  />
                    </Button> <span>No destacar </span>
                    </div> 
                    </> : 
                    <>
                            <div style={{ marginLeft:"5px",marginBottom:"5px"  }}>


                    <Button
                      shape="circle"
                      danger
                      type="default"
                      onClick={(e) => {
                        cambiarPromocionado(record, e);
                      }}
                    >
                      <StarFilled style={{  color: 'yellow' }} />
                    </Button> <span>Destacar</span>
                    </div> 
                    
                    </>
                ) :
                <></>
            ) : 
            <></>
        ) : 
        <></>
    }
    </>
  );


const botonesfoto = (record) => (

    <>
    {
    ( typeof record !== "undefined" && typeof record.foto !== "undefined") ?     
      (<>
        <div style={{   marginLeft:"5px"}}>

        <FormDialog
          key={record.foto}
          value={record.foto}
          plato={record.plato}
        />  <span>Ver Foto</span> <Button
          shape="circle"
          type="default"
          onClick={(e) => {
            borrarFoto(record, e);
          }}
        >
          <MinusCircleTwoTone twoToneColor="#eb2f96" />
        </Button> <span>Eliminar </span>
         </div>
      </>)
      :
      (<>
        <div style={{ }}>
          <div style={{ float:"left", marginLeft:"5px"}}>
          
                      <Upload onChange={handleAnt}>
                        <Button shape="circle">
                          <CameraTwoTone />
                        </Button> <span>Seleccionar Foto </span> </Upload>
        </div>
        <div style={{ float:"left", marginLeft:"5px"}}>
                    
                      <Button shape="circle" onClick={(e) => sendAnt(record)}>
                        <PlusCircleTwoTone twoToneColor="#52c41a" />
                      </Button> <span>Enviar Foto</span>
                      </div>
                      </div>

      </>)
    

    }


    </>
)


  
var totalPublicados =  document.getElementsByClassName("publicado").length; 
var totalDespublicados =  document.getElementsByClassName("despublicado").length; 
var totalDestacados =  document.getElementsByClassName("destacado").length; 
var totalNodestacados =  document.getElementsByClassName("nodestacado").length; 
var totalPlatos = totalDespublicados + totalPublicados;

var mensaje = 'Se muestran '+totalPublicados+' platos publicados de un total de '+totalPlatos+' creados'


  return (
    <Layout>
      {/* <Header
                titulo="Platos"
                subtitulo={`Bienvenido ${nombre}`}
            /> */}
      <MenuAdmin titulo="Platos" />

      <div style={{marginTop: "10px", marginBottom:"0px", padding: "10px"}}>


      <Input
              prefix={<SearchOutlined />}

      placeholder="Buscar plato"
      value={value}
      onChange={e => {
        const currValue = e.target.value;
        setValue(currValue);
        
        
        const filteredData = dataSource.filter(entry =>
          entry.plato.toLowerCase().includes(currValue.toLowerCase())
        );

        setData(filteredData);
      }}
    />

</div>


      <Content style={mystyle}>

        <div style={tableWrapper}>
        {/*<Alert message={`${mensaje}` } type="info" showIcon closable/>*/}
        
          <div
            style={{
              paddingBottom: "25px",
              paddingTop: "25px",
              paddingLeft: "25px",
              paddingRight: "25px",
              float: "left",
            }}
          >
            <span>Despublicados </span>

            <Switch
              checkedChildren={<CheckOutlined />}
              defaultChecked
              unCheckedChildren={<CloseOutlined />}
              onChange={handleClickDisabled} 
            />
          </div>

          <div
            style={{
              paddingBottom: "25px",
              paddingTop: "25px",
              paddingLeft: "25px",
              paddingRight: "25px",
              float: "right"

            }}
          >
            <span>No destacados </span>

            <Switch
              checkedChildren={<CheckOutlined />}
              defaultChecked
              unCheckedChildren={<CloseOutlined />}
              onChange={handleClickPromoted}
            />
          </div>

   

          <Table
          
            columns={columns}
            expandedRowRender={(record) => {

              
                return (
                  <>

                  <div style={{  display:"flex", flexWrap:"wrap", justifyContent:"flex-start" }}>
                    {botonesfoto(record)}
                  </div>
                  <hr />
                  <div style={{  display:"flex", flexWrap:"wrap", justifyContent:"flex-start" }}>

                    {botoneditar(record)}  
                    {botonpublicar(record)}  
                    {botonportada(record)} 
                    {botonborrar(record)}  

                    </div>
                  </>
                );
              } 
            }
            onChange={() => setChecked(false)}
            size="small"
            rowKey="id"
            dataSource={data!=null?data:dataSource}
            pagination={false}
            rowClassName={(record, index) => 
              record.publicado === true ? 
              record.portada === true ? "destacado publicado " : "nodestacado publicado "
              : 
              record.portada !== true ? "nodestacado despublicado " : "destacado despublicado "
            

            }
            

          />
        </div>
      </Content>
      <Footer style={{ textAlign: "center" }}></Footer>
    </Layout>
  );
};
export default withRouter(Dashboard);
