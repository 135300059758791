import React from 'react';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({

}));

function Carousel() {
  
  const classes = useStyles();

  return(
    <>
    <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
        <div className="carousel-inner">
        <div className="carousel-item active">
            <img src="./img/ellindero.jpg" className="d-block w-100" alt="food" />  
        </div>
        <div className="carousel-item ">
            <img src="./img/interior1.jpg" className="d-block w-100" alt="food" />  
        </div>
        <div className="carousel-item">
            <img src="./img/interior2.jpg" className="d-block w-100" alt="food" />
        </div>
        <div className="carousel-item">
            <img src="./img/interior3.jpg" className="d-block w-100" alt="food" />
        </div>
        <div className="carousel-item">
            <img src="./img/interior5.jpg" className="d-block w-100" alt="food" />
        </div>
        <div className="carousel-item">
            <img src="./img/interior6.jpg" className="d-block w-100" alt="food" />
        </div>
        </div>
        <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="sr-only">Previous</span>
        </a>
        <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="sr-only">Next</span>
        </a>
    </div>
    </>
);

}
export default Carousel;



