
import React, { useEffect, useContext, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import FormDialog from './Modal';

import { Auth } from "../context/AuthContext";
import { denominaciones, grupos } from "../config/constantes"
import { Table, Tag, Space, Input, Button, Select, Avatar, Popconfirm, message, Collapse, Modal, Icon } from 'antd';


import firebase from 'firebase/app';



const useStyles = makeStyles((theme) => ({


    alergenos: {
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        justifyContent: "space-between",
        listStyleType: "none",
        "& li":{
            marginTop: "4px"
        },
        "& li img": {
            width: "20px",
            marginRight: "4px"
        }
    }

}
)
);



const Alergenos = (datos) => {

    const classes = useStyles();

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };



    const handleOk = () => {
        setIsModalVisible(false);
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };
    
    if (typeof datos.alergenos !== 'undefined' ) {
        return (
            <>
                <Icon style={{ fontSize: '16px', border: '1px solid ', padding: '2px' }}  type="search" onClick={showModal} />
                <Modal title="Alérgenos" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} >
                    <p>El plato <strong>{datos.plato}</strong> puede contener los siguientes alérgenos</p>
                    <ul className={classes.alergenos}>

                        {datos.alergenos.cacahuete && <li><img src="./img/alergenos/cacahuete.png" /><span>Cacahuete</span></li>}
                        {datos.alergenos.apio && <li><img src="./img/alergenos/apio.png" /><span>Apio</span></li>}
                        {datos.alergenos.altramuces && <li><img src="./img/alergenos/altramuces.png" /><span>Altramuces</span></li>}
                        {datos.alergenos.cascara && <li><img src="./img/alergenos/cascara.png" /><span>Frutos con cáscara</span></li>}
                        {datos.alergenos.crustaceos && <li><img src="./img/alergenos/crustaceos.png" /><span>Crustáceos</span></li>}
                        {datos.alergenos.gluten && <li><img src="./img/alergenos/gluten.png" /><span>Gluten</span></li>}
                        {datos.alergenos.huevo && <li><img src="./img/alergenos/huevo.png" /><span>Huevo</span></li>}
                        {datos.alergenos.lacteos && <li><img src="./img/alergenos/lacteos.png" /><span>Lácteos</span></li>}
                        {datos.alergenos.molusco && <li><img src="./img/alergenos/molusco.png" /><span>Moluscos</span></li>}
                        {datos.alergenos.mostaza && <li><img src="./img/alergenos/mostaza.png" /><span>Mostaza</span></li>}
                        {datos.alergenos.pescado && <li><img src="./img/alergenos/pescado.png" /><span>Pescado</span></li>}
                        {datos.alergenos.sesamo && <li><img src="./img/alergenos/sesamo.png" /><span>Semillas de sésamo</span></li>}
                        {datos.alergenos.soja && <li><img src="./img/alergenos/soja.png" /><span>Soja</span></li>}
                        {datos.alergenos.sulfitos && <li><img src="./img/alergenos/sulfitos.png" /><span>Sulfitos</span></li>}
                    </ul>
                </Modal>
            </>
        );

    } else return <></>


}
export default Alergenos;