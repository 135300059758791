import React, { useEffect, useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import firebase from "firebase/app";

import { denominaciones, grupos, options } from "../config/constantes";


import Icon, { HeartOutlined } from '@ant-design/icons';


const useStyles = makeStyles((theme) => ({
  titulo: {
    fontStyle: "normal",
    fontFamily: "Roboto Slab",
    color: "#fff",
    paddingLeft: "25px",
    paddingTop: "15px",
    fontSize: "2em",
  },
  sugerencias: {
    background: "#2f3840",
    paddingBottom: "25px",
  },
  texto: {
    color: "#baa470",
    float: "left",
    width: "35%",
    paddingLeft: "20px",
    fontSize: "20px",
    fontFamily: "Roboto Slab",
  },
  
}));

const HeartSvg = () => (
  <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 1024 1024">
    <path d="M923 283.6c-13.4-31.1-32.6-58.9-56.9-82.8-24.3-23.8-52.5-42.4-84-55.5-32.5-13.5-66.9-20.3-102.4-20.3-49.3 0-97.4 13.5-139.2 39-10 6.1-19.5 12.8-28.5 20.1-9-7.3-18.5-14-28.5-20.1-41.8-25.5-89.9-39-139.2-39-35.5 0-69.9 6.8-102.4 20.3-31.4 13-59.7 31.7-84 55.5-24.4 23.9-43.5 51.7-56.9 82.8-13.9 32.3-21 66.6-21 101.9 0 33.3 6.8 68 20.3 103.3 11.3 29.5 27.5 60.1 48.2 91 32.8 48.9 77.9 99.9 133.9 151.6 92.8 85.7 184.7 144.9 188.6 147.3l23.7 15.2c10.5 6.7 24 6.7 34.5 0l23.7-15.2c3.9-2.5 95.7-61.6 188.6-147.3 56-51.7 101.1-102.7 133.9-151.6 20.7-30.9 37-61.5 48.2-91 13.5-35.3 20.3-70 20.3-103.3 0.1-35.3-7-69.6-20.9-101.9z" />
  </svg>
);

const HeartIcon = (props) => <Icon component={HeartSvg} {...props} />;


const Sugerencias = () => {
  const classes = useStyles();

  const initialValue = [];
  const [carta, setCarta] = useState(initialValue);

  let allcarta = [];
  let dataSource = [];

  carta.length > 0 &&
    carta.map((item, i) => {
        Object.keys(item).length > 0 &&
        Object.values(item).map((platos, j) => {
         if(j==1){

          let keys = Object.keys(item);

          Object.values(platos).map((plato, k) => {


            plato.publicado && plato.portada && plato.foto &&
              dataSource.push({
                key: keys[j],
                grupo: i + 1,
                plato: plato.nombreplato,
                media: plato.media,
                racion: plato.racion,
                publicado: plato.publicado,
                foto: plato.foto,
                alergenos: plato.alergenos,
                portada: plato.portada?plato.portada:false
              });
  
          })

         }
        });
    });

  useEffect(() => {
    const datos = firebase.database().ref().child("carta/grupos");
    datos
      .once("value", (snapshot) => {
        snapshot.forEach((snap) => {
          allcarta.push(snap.val());
        });
      })
      .then(function () {
        setCarta(allcarta);
      });
  }, []);
  return (
    <>
      <section className={classes.sugerencias}>
        <h2 className={classes.titulo}>Te recomendamos...</h2>
        <div class="container">
          <div class="card-columns justify-content-around">
            
            {dataSource.map((datos, index) => {
              return (
                <>
                  { (datos.publicado  ) ? (
                    <div class="card">
                      <img
                        class="card-img-top"
                        src={datos.foto}
                        alt="Card image cap"
                      />
                      <div class="card-body">
                        <h5 class="card-title">{datos.plato} </h5>
                        <p class="card-text">{grupos[datos.grupo]}</p>
                      </div>
                       <div class="card-footer">
                        <small class="text-muted float-left">
                          <strong>{ (datos.media)? 'Media: '+datos.media+'€ | ':''}   { (datos.racion)? 'Ración: '+datos.racion+'€': ''}</strong>
                        </small>
                        <span class="float-right"><HeartIcon style={{color: 'hotpink',}}    /></span>
                        <p></p>
                      </div>
                    </div>
                  ) : null}
                </>
              );
            })}
          </div>

          <small>* Sugerencias de presentación</small>
        </div>
        {/*
    <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
        <div className="carousel-inner">
        <div className="carousel-item active">
            <img src="https://via.placeholder.com/150" className="d-block float-left ml-4 sugerenciafoto " alt="food" />  
            <p className={classes.texto}>Lorem ipsum dolor sit amet consectuector</p>
        </div>
        <div className="carousel-item ">
        <img src="https://via.placeholder.com/150" className="d-block float-left ml-4 sugerenciafoto" alt="food" />  
            <p className={classes.texto}>Lorem ipsum dolor sit amet consectuector</p>
  
        </div>
        <div className="carousel-item">
        <img src="https://via.placeholder.com/150" className="d-block float-left ml-4 sugerenciafoto" alt="food" />  
            <p className={classes.texto}>Lorem ipsum dolor sit amet consectuector</p>

        </div>
        <div className="carousel-item">
        <img src="https://via.placeholder.com/150" className="d-block float-left ml-4 sugerenciafoto" alt="food" />  
            <p className={classes.texto}>Lorem ipsum dolor sit amet consectuector</p>

        </div>
        <div className="carousel-item">
        <img src="https://via.placeholder.com/150" className="d-block float-left ml-4 sugerenciafoto" alt="food" />  
            <p className={classes.texto}>Lorem ipsum dolor sit amet consectuector</p>

        </div>
        <div className="carousel-item">
        <img src="https://via.placeholder.com/150" className="d-block float-left ml-4 sugerenciafoto" alt="food" />  
            <p className={classes.texto}>Lorem ipsum dolor sit amet consectuector</p>

        </div>
        </div>
        <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="sr-only">Previous</span>
        </a>
        <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="sr-only">Next</span>
        </a>
    </div>
  */}
      </section>
    </>
  );
};
export default Sugerencias;
