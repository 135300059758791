import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import VisibilityIcon from '@material-ui/icons/Visibility';
//import Button from '@material-ui/core/Button';
import { Table, Tag, Space, Button, message, Switch, Modal, Upload} from 'antd';
import { CheckOutlined, CloseOutlined, CheckCircleTwoTone, CloseCircleTwoTone, EditOutlined, EyeTwoTone, EyeInvisibleTwoTone, DeleteOutlined, HomeTwoTone,CameraOutlined, ConsoleSqlOutlined } from '@ant-design/icons';

export default function FormDialog(props) {
    const [open, setOpen] = React.useState(false);
  
    function handleClickOpen() {
      setOpen(true);
    }
  
    function handleClose() {
      setOpen(false);
    }
  
    return (
      <>
        <Button shape="circle" type="default" onClick={handleClickOpen}>
          <CameraOutlined   />
          </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">{props.plato}</DialogTitle>
          <DialogContentText id="alert-dialog-description">
                <img width="100%" src={`${props.value}`} />
          </DialogContentText>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }