import React, { useEffect, useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormDialog from "./Modal";

import { Auth } from "../context/AuthContext";
import { denominaciones, grupos, options } from "../config/constantes";

import Alergenos from "./Alergenos";

import firebase from "firebase/app";

import {
  InstagramOutlined,
  FacebookOutlined,
  ToolOutlined,
  UserOutlined,
} from "@ant-design/icons";

const useStyles = makeStyles((theme) => ({
  carta: {
    padding: "10px 20px 10px 20px",
    textAlign: "center",
    fontFamily: "Roboto Slab",
    "& p": {
      textAlign: "left",
    },
  },
  cartaItems: {
    display: "flex",
    marginBottom: "5px",
    justifyContent: "space",
    fontSize: "12px",
    "@media print": {
      fontSize: "16px !important",
    },
    "& span:first-child ": {
      display: "flex",
      justifyContent: "space-between",
      width: "70%",
      textAlign: "left",
    },
    "& span": {
      width: "15%",
      "& i": {
        "@media print": {
          display: "none",
        },
      },
    },
  },
  plato: {
    display: "flex",
    justifyContent: "flex-start",
    width: "33%",
  },

  vinosItems: {
    display: "flex",
    marginBottom: "5px",
    justifyContent: "space",
    fontSize: "12px",
    "@media print": {
      fontSize: "16px !important",
    },
    "& span:first-child ": {
      display: "flex",
      justifyContent: "flex-start",
      width: "33%",
      textAlign: "left",
    },
    "& span:last-child ": {
      width: "10%",
      textAlign: "right",
    },
    "& span": {
      width: "25%",
      textAlign: "left",
    },
  },
  do: {
    width: "33% !important",
    fontStyle: "italic",
  },
  h2: {
    textAlign: "left",
    fontSize: "20px",
    "@media print": {
      fontSize: "28px !important",
    },
  },
  grupo: {
    paddingInlineStart: "0px",
  },
  textCenter: {
    textAlign: "center",
  },
  textRight: {
    textAlign: "right",
  },
  oculto: {
    "@media print": {
      display: "none",
    },
  },
  soloprint: {
    display: "none",
    "@media print": {
      pageBreakBefore: "always",

      display: "block",
    },
  },

  h2conSalto: {
    textAlign: "left",
    fontSize: "20px",

    "@media print": {
      pageBreakBefore: "always",
      textAlign: "left",
      fontSize: "28px !important",
    },
  },
  alergenos: {
    display: "flex",
    flexWrap: "wrap",
    /*justifyContent: "space-between",*/
    marginTop: "20px",
    fontSize: "10px",

    "& div": {
      /*marginRight: "2px",
      marginLeft: "2px",
      marginBottom: "10px",
      textAlign: "center",*/
      display: "inline-block",
      margin: "0px 0 0 3px",
      flexGrow: "1",
      width: "calc(100% * (1/7) - 3px - 1px)",

      "& p": {
        textAlign: "center",
      },
    },
    "& img": {
      width: "20px",
    },
  },
  h5: {
    width: "100%",
  },
}));

const Carta = () => {
  const classes = useStyles();

  const { usuario } = useContext(Auth);
  const [nombre, setnombre] = useState(null);

  const initialValue = [];
  const [carta, setCarta] = useState(initialValue);
  const [cartaVinos, setCartaVinos] = useState(initialValue);

  let allcarta = [];
  let allcartavinos = [];
  let dataSource = [];

  let dataSourceVinos = [];

  carta.length > 0 &&
    carta.map((item, i) => {
      item.platos != null &&
        Object.keys(item.platos).length > 0 &&
        Object.values(item.platos).map((plato, j) => {
          let keys = Object.keys(item.platos);

          dataSource.push({
            key: keys[j],
            grupo: i + 1,
            plato: plato.nombreplato,
            media: plato.media,
            racion: plato.racion,
            publicado: plato.publicado,
            foto: plato.foto,
            alergenos: plato.alergenos,
          });
        });
    });
  cartaVinos.length > 0 &&
    cartaVinos.sort((a, b) => (a.vino > b.vino ? 1 : -1));

  cartaVinos.length > 0 &&
    cartaVinos.map((item, i) => {
      dataSourceVinos.push({
        bodega: item.bodega,
        anyo: item.anyo,
        vino: item.vino,
        uva: item.uva,
        envejecimiento: item.envejecimiento,
        origen: item.origen,
        precio: item.precio,
        orden: item.orden,
        publicado: item.publicado,
      });
    });

  useEffect(() => {
    usuario
      ? usuario.displayName
        ? setnombre(usuario.displayName)
        : setnombre(usuario.email)
      : setnombre(null);
  }, [usuario]);

  useEffect(() => {
    const datos = firebase.database().ref().child("carta/grupos");
    datos
      .once("value", (snapshot) => {
        snapshot.forEach((snap) => {
          allcarta.push(snap.val());
        });
      })
      .then(function () {
        setCarta(allcarta);
      });

    const datosvinos = firebase.database().ref().child("vinos");

    datosvinos
      .once("value", (snapshot) => {
        snapshot.forEach((snap) => {
          allcartavinos.push(snap.val());
        });
      })
      .then(function () {
        setCartaVinos(allcartavinos);
      });
  }, []);

  const arrayPlatos = ["1", "2", "3"];
  let aux;
  return (
    <>
      <div className={classes.carta}>
      <h1>El Lindero Gastrotaberna</h1>
        <h2 className={classes.oculto}>Carta</h2>

        {/*usuario && <div className={classes.oculto}><p className={classes.textCenter}>Bienvenido {usuario.displayName}, aquí puede ver nuestra carta, le recomendamos que pregunte por nuestras sugerencias del día</p></div>*/}

        <span className={classes.cartaItems}>
          <span></span>
          <span>Media</span>
          <span>Ración</span>
          <span>Alérgenos</span>
        </span>

        {/* {usuario && ( usuario.email==='juancalero@gmail.com' || usuario.email==='carloscandelariovazquez@gmail.com') && */}
        <>
          <hr />
          {dataSource.map((datos) => {
            if (datos.grupo != aux) {
              aux = datos.grupo;

              return (
                <>
                  {datos.grupo == 7 ? (
                    <>
                      <div className={classes.soloprint}>
                        <span className={classes.cartaItems}>
                          <span></span>
                          <span>Media</span>
                          <span>Ración</span>
                          <span>Alérgenos</span>
                        </span>
                        <hr />
                      </div>
                      <h2 className={classes.h2}>{grupos[datos.grupo]}</h2>
                    </>
                  ) : (
                    <h2 className={classes.h2}>{grupos[datos.grupo]}</h2>
                  )}

                  {/*<h2 key={datos.grupo} className={classes.h2}>{grupos[datos.grupo]}</h2>*/}
                  {datos.publicado ? (
                    <div key={datos.plato} className={classes.cartaItems}>
                      <span>
                        {datos.plato}{" "}
                        {datos.foto && false && (
                          <div className={classes.oculto}><FormDialog
                            key={datos.foto}
                            value={datos.foto}
                            plato={datos.plato}
                          /></div> 
                        )}{" "}
                      </span>
                      <span>{datos.media}</span>
                      <span>{datos.racion}</span>
                      <span className={classes.oculto}>
                        <Alergenos
                          alergenos={datos.alergenos}
                          plato={datos.plato}
                        />
                      </span>
                      <span className={classes.soloprint}>
                        {typeof datos.alergenos !== "undefined" ? (
                          <>
                            {Object.entries(datos.alergenos).map((dato, i) => {
                              let rutaimagen =
                                "./img/alergenos/" + dato[0] + ".png";
                              if (dato[1])
                                return (
                                  <img
                                    width="20px"
                                    height="20px"
                                    src={rutaimagen}
                                  />
                                );
                            })}
                          </>
                        ) : (
                          <> </>
                        )}
                      </span>
                    </div>
                  ) : null}
                </>
              );
            } else {
              return datos.publicado ? (
                <div key={datos.plato} className={classes.cartaItems}>
                  <span>
                    {datos.plato}{" "}
                    {datos.foto && false && (
                      <><FormDialog className={classes.oculto}
                        key={datos.foto}
                        value={datos.foto}
                        plato={datos.plato}
                      /></>
                    )}{" "}
                  </span>

                  <span>{datos.media}</span>
                  <span>{datos.racion}</span>
                  <span className={classes.oculto}>
                    <Alergenos
                      alergenos={datos.alergenos}
                      plato={datos.plato}
                    />
                  </span>
                  <span className={classes.soloprint}>
                    {typeof datos.alergenos !== "undefined" ? (
                      <>
                        {Object.entries(datos.alergenos).map((dato, i) => {
                          let rutaimagen =
                            "./img/alergenos/" + dato[0] + ".png";
                          if (dato[1])
                            return (
                              <img
                                width="20px"
                                height="20px"
                                src={rutaimagen}
                              />
                            );
                        })}
                      </>
                    ) : (
                      <> </>
                    )}
                  </span>
                </div>
              ) : null;
            }
          })}

          <hr />
          <h6>Alérgenos</h6>

          <div className={classes.alergenos}>
            {options.map((dato, i) => {
              let rutaimagen = "./img/alergenos/" + dato["value"] + ".png";

              return (
                <div>
                  <img width="20px" height="20px" src={rutaimagen} />
                  <p> {dato["label"]}</p>
                </div>
              );
            })}
          </div>
          <hr />

          <h3>Vinos</h3>
          <div className={classes.vinosItems}>
            <span></span>
            <span>D.O.</span>
            <span>Envejecimiento</span>
            <span className={classes.textRight}>Precio</span>
          </div>
          <hr />

          {dataSourceVinos
            .sort((a, b) => (a.orden > b.orden ? 1 : -1))
            .map((datosvinos) => {
              if (datosvinos.publicado)
                return (
                  <div key={datosvinos.vino} className={classes.vinosItems}>
                    <span>{datosvinos.vino} </span>
                    <span>{denominaciones[datosvinos.origen]}</span>
                    <span>
                      {datosvinos.envejecimiento} {datosvinos.anyo}
                    </span>
                    <span className={classes.textRight}>
                      {datosvinos.precio}
                    </span>
                  </div>
                );
            })}

          <a className={classes.oculto} href="/">
            Volver
          </a>
        </>
        <hr />
        <div className={classes.oculto}>
          <a
            target="_blank"
            href="https://www.facebook.com/El-Lindero-105823741200986"
          >
            <FacebookOutlined style={{ fontSize: "28px" }} />
          </a>{" "}
          &nbsp;
          <a
            target="_blank"
            href="https://www.instagram.com/linderotaberna/"
          >
            <InstagramOutlined style={{ fontSize: "28px" }} />
          </a>{" "}
        </div>
      </div>
    </>
  );
};
export default Carta;
