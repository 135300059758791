import React, { useEffect, useContext, useState } from "react";
import Header from "../components/Header";

import { Layout } from "antd";
import { Table, Tag, Space } from 'antd';

import { Auth } from "../context/AuthContext";
import { withRouter } from "react-router";

import firebase from 'firebase/app';
import { denominaciones, grupos, allowedMails } from "../config/constantes"
import MenuAdmin from '../components/MenuAdmin'

import { Form, Input, Button, Select } from 'antd';

const { Option } = Select;
const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 20,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};
const AltaVino = ({ history, props }) => {
    const { Content, Footer } = Layout;
    const { usuario } = useContext(Auth);

    const [nombre, setNombre] = useState(null);
    const [valueOrigen, setValueOrigen] = useState(null)

    const initialValue = []
    const [carta, setCarta] = useState(initialValue);
    const [formData, updateFormData] = useState({});


    const [error, seterror] = useState("");

    const handleSetPlato = (e) => {
        e.preventDefault();
        const { vino, uva, anyo, publicado, envejecimiento, origen, bodega, precio, orden } = formData;

        console.log("formData", formData);
        var post = firebase.database().ref().child('vinos');
        var newpost = post.push();
        newpost.set({
            origen: origen ? origen : null,
            vino: vino,
            uva: uva ? uva : null,
            anyo: anyo ? anyo : null,
            publicado: publicado ? publicado : false,
            envejecimiento: envejecimiento ? envejecimiento : null,
            bodega: bodega ? bodega : null,
            precio: precio ? precio : null,
            orden: orden ? orden : null,

        })
        e.target.reset();
        history.push("/vinos");

    };



    const handleOnChange = (e) => {
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value.trim()
        })
    }

    const handleOnChangeGrupo = (value, e) => {

        setValueOrigen(value)
        updateFormData({
            ...formData,
            origen: value,
        })
    }

    useEffect(() => {
        firebase.database().ref().child('vinos').once("value", snapshot => {
            snapshot.forEach(snap => {
                //allcarta.push(snap.val());
            })
        })
            .then(function () {
                //setCarta(allcarta);
            })

    }, [])

    useEffect(() => {
        //let allowedMails = ['juancalero@gmail.com', 'carloscandelariovazquez@gmail.com']
        if (usuario === null || !allowedMails.includes(usuario.email)) {
            history.push("/");
        }
        usuario ? usuario.displayName ? setNombre(usuario.displayName) : setNombre(usuario.email) : setNombre(null)
    }, [usuario]);



    const mystyle = (window.innerWidth > 900) ? { padding: "0px 50px" } : { padding: "0px" }
    const tableWrapper = (window.innerWidth > 900) ? { background: "#fff", minHeight: "80vh", padding: 24 } : { background: "#fff", minHeight: "80vh", padding: 10 }

    return (
        <Layout style={{ height: "100vh" }}>
            {/* <Header
                titulo="Añadir Vino"
                subtitulo={`Bienvenido ${nombre}`}
            /> */}
            <MenuAdmin titulo="Alta Vino" />
            <Content style={mystyle}>
                <div style={tableWrapper}>
                    <Form {...layout} id="altaVino" name="control-hooks" onSubmit={handleSetPlato} >
                        <Form.Item
                            label="Vino"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input
                                name="vino"
                                onChange={handleOnChange}
                            />
                        </Form.Item>
                        <Form.Item
                            name="anyo"
                            label="Año"
                            onchange={handleOnChange}
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input
                                name="anyo"
                                onChange={handleOnChange}
                            />
                        </Form.Item>
                        <Form.Item
                            name="uva"
                            label="Uva"
                            onChange={handleOnChange}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input
                                name="uva"
                                onChange={handleOnChange}
                            />
                        </Form.Item>
                        <Form.Item
                            name="envejecimiento"
                            label="Envejecimiento"
                            onChange={handleOnChange}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input
                                name="envejecimiento"
                                onChange={handleOnChange}
                            />
                        </Form.Item>
                        <Form.Item
                            name="bodega"
                            label="Bodega"
                            onChange={handleOnChange}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input
                                name="bodega"
                                onChange={handleOnChange}
                            />
                        </Form.Item>
                        <Form.Item
                            name="precio"
                            label="Precio"
                            onChange={handleOnChange}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input
                                name="precio"
                                onChange={handleOnChange}
                            />
                        </Form.Item>
                        <Form.Item

                            label="Denominación de Origen"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                name="origen"
                                onSelect={(value, e) => handleOnChangeGrupo(value, e)}

                                placeholder="Select a option and change input text above"
                                allowClear
                            >
                                {
                                    denominaciones.map((deno, i) => { return <Option value={i}>{deno}</Option> })
                                }

                            </Select>
                        </Form.Item>

                        <Form.Item
                            name="orden"
                            label="Orden"
                            onChange={handleOnChange}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input
                                name="orden"
                                onChange={handleOnChange}
                            />
                        </Form.Item>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => prevValues.gender !== currentValues.gender}
                        >
                            {({ getFieldValue }) =>
                                getFieldValue('grupo') === 'other' ? (
                                    <Form.Item
                                        name="customizeGender"
                                        label="Customize Gender"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                ) : null
                            }
                        </Form.Item>
                        <Form.Item {...tailLayout}>
                            <Button type="submit" htmlType="submit"> Submit </Button>
                        </Form.Item>
                    </Form>
                </div>

            </Content>
            <Footer style={{ textAlign: "center" }}>

            </Footer>
        </Layout>
    );

}
export default withRouter(AltaVino);
