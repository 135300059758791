import React, { useEffect, useContext, useState, useRef } from "react";
import Header from "../components/Header";

import { Layout } from "antd";
import { Table, Tag, Space } from 'antd';

import { Auth } from "../context/AuthContext";
import { withRouter } from "react-router";

import firebase from 'firebase/app';

import { Form, Input, Button, Select } from 'antd';
import MenuAdmin from '../components/MenuAdmin'

import HorariosDataService from "../services/HorariosService";


const { Option } = Select;
const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 20,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};
const EditarMenu = ({ history, props }) => {
    const { Content, Footer } = Layout;
    const { usuario } = useContext(Auth);

    const [nombre, setNombre] = useState(null);
    const [valueGrupo, setValueGrupo] = useState(null)

    const initialValue = []
    const [carta, setCarta] = useState(initialValue);
    const [horarios, setHorarios] = useState(initialValue);
    const [formData, updateFormData] = useState({});
    const refContainer = useRef(initialValue);


    const [error, seterror] = useState("");

    const inputEl = useRef(null);

  const cambiaHorario = (idx) => {

console.log(formData)
    const itemHorario = {
        horariodia: formData.horariodia
      
    }
    // alert('/carta/grupos/'+grupo+'/platos/'+key)
    HorariosDataService.update(idx+1,itemHorario)
      .then(() => {
        console.log("The item was updated successfully!");
      })
      .catch((e) => {
        console.log(e);
      });
  }


    const handleSetMenu = (e) => {
        e.preventDefault();
        const { menu } = formData;

        var post = firebase.database().ref().child('menus');
        //var newpost = post.push();
        post.update({
            menu: menu,
        })
        e.target.reset();
        history.push("/platos");

    };



    const handleOnChange = (e) => {
        console.log("e", e);
        updateFormData({
            horariodia: e.target.value.trim()
        })
    }

    const handleOnChangeGrupo = (value, e) => {
        console.log("value", value);
        console.log("e", e);
        setValueGrupo(value)
        updateFormData({
            ...formData,
            grupo: value,
        })
    }

    let allcarta = []

    useEffect(() => {
        firebase.database().ref().child('menus').once("value", snapshot => {
            snapshot.forEach(snap => {
                allcarta.push(snap.val());
            })
        })
            .then(function () {
                setCarta(allcarta);
            })

    }, [])

    


    const onDataChange = (items) => {
        
        let horariosAux = [];

        items.forEach((item) => {
          let data = item.val();
          horariosAux.push({
            dia: data.dia,
            horariodia: data.horariodia
          });
        });
        //horariosAux.sort((a, b) => (a.grupo > b.grupo) ? 1 : -1)
    
    
        setHorarios(horariosAux);
      };
    
      useEffect(() => {
        HorariosDataService.getAll().on("value", onDataChange);
    
        return () => {
          HorariosDataService.getAll().off("value", onDataChange);
        };
      }, []);
    





    useEffect(() => {

        if (usuario === null) {
            history.push("/");
        }
        usuario ? usuario.displayName ? setNombre(usuario.displayName) : setNombre(usuario.email) : setNombre(null)

    }, [usuario]);


    const mystyle = (window.innerWidth > 900) ? { padding: "0px 50px", marginTop: 40 } : { padding: "0px", marginTop: 40 }
    const tableWrapper = (window.innerWidth > 900) ? { background: "#fff", minHeight: "80vh", padding: 24 } : { background: "#fff", minHeight: "80vh", padding: 10 }
    const layoutStyle = (window.innerWidth > 900) ? { height: "100vh" } : { height: "auto" }


    const { TextArea } = Input;

    return (
        <Layout style={layoutStyle}>

        <MenuAdmin titulo="Editar menu y horario" />

            <Content style={mystyle}>
                <div style={tableWrapper}>
                    <Form {...layout} id="editarmenu" name="control-hooks"  setFieldsValue ={{['menuitem']: carta[0]}} >
                        <Form.Item
                            label="Menu"
                            name="menuitem"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <TextArea rows={8}
                                name="menuitem"
                                onChange={handleOnChange}
                            />
                        </Form.Item>
                        <Form.Item {...tailLayout}>
                            <Button type="submit" htmlType="submit"> Submit </Button>
                        </Form.Item>

                        <div>Menú actual: <p>{carta && carta[0]}</p></div>

                        {horarios && horarios[0] && horarios.map((item, idx) => {
                            return <Form.Item
                            label={item ? item.dia: ''}
                            name={item && item ? item.dia+'horario': ''}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                            >
                                <Input style={{ width: 200 }} onChange={handleOnChange} name={ item ? item.dia+'horario': ''} key={ item ? item.dia: ''} defaultValue={ item ? item.horariodia : 'Loading...' } />
                                <Button type="submit" htmlType="submit" onClick={()=>cambiaHorario(idx) }>Actualizar</Button>

                            </Form.Item>


                         } ) }


                        
                                                                                                                                                
                        
                    </Form>
                    
                </div>

            </Content>
            <Footer style={{ textAlign: "center" }}>

            </Footer>
        </Layout>
    );

}
export default withRouter(EditarMenu);
