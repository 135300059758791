const firebaseConfig = {
    apiKey: "AIzaSyAHUwttsX2ius23zxVOXOOcNEDem7FbVAw",
    authDomain: "ellinderotaberna.firebaseapp.com",
    databaseURL: "https://ellinderotaberna.firebaseio.com",
    projectId: "ellinderotaberna",
    storageBucket: "ellinderotaberna.appspot.com",
    messagingSenderId: "770099512096",
    appId: "1:770099512096:web:c149e579529e26d5638d45",
    measurementId: "G-H9P86CGBK0"
}
export default firebaseConfig;
