import React, { useEffect, useContext, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import FormDialog from './Modal';

import { Auth } from "../context/AuthContext";
// import denominaciones from "../config/constantes"
import { allowedMails } from "../config/constantes"

import { Table, Tag, Space, Input, Button, Select, Avatar, Popconfirm, message } from 'antd';

import firebase from 'firebase/app';
import { v4 as uuidv4 } from 'uuid';
import Moment from 'react-moment';

import ComandasDataService from "../services/ComandasService";

import { useList } from "react-firebase-hooks/database";
import MenuAdmin from '../components/MenuAdmin'

import { InstagramOutlined, FacebookOutlined, ToolOutlined, UserOutlined, ExclamationOutlined, NotificationOutlined, DeleteOutlined, CloseCircleOutlined, CheckOutlined, ClockCircleTwoTone } from '@ant-design/icons';
import { denominaciones, grupos } from "../config/constantes"

const { Option } = Select;

const useStyles = makeStyles((theme) => ({
  carta: {
    padding: "10px 20px 10px 20px",
    textAlign: "center",
    "& p": {
      textAlign: "left"
    }

  },
  cartaItems: {
    display: "flex",
    marginBottom: '5px',
    justifyContent: "space",
    fontSize: '12px',
    "& .ant-select": {
      width: "40%",
      marginRight: "10px"
    },
    "& span:first-child ": {
      display: "flex",
      justifyContent: "space-between",
      width: "60%",
      textAlign: "left",

    },
    "& span": {
      width: "20%",
    },
    "& span.ant-select-arrow": {
      width: "auto",
    }
  },

  plato: {
    display: "flex",
    justifyContent: "flex-start",
    width: "33%",
  },
  h2: {
    textAlign: "left",
    fontSize: '20px'
  },
  grupo: {
    paddingInlineStart: '0px'
  },
  textCenter: {
    textAlign: "center"
  },
  comandaWrapper: {
    border: '1px solid #eee',
    padding: '8px'
  },
  pedidoComanda: {
    textAlign: 'left',
    listStyle: 'none',
    paddingLeft: 0,
  },
  itemPedidoComanda: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '3px'
  },
  avatar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px',
    background: '#eee'
  },
  hecho: {
    "& span.plato": {
      textDecoration: "line-through"
    }
  },
  oculto: {
    display: "none"
  },
  mediaIcon: {
    width: "10px",
    height: "20px",
    backgroundColor: "white",
    borderBottomLeftRadius: "10px",
    borderTopLeftRadius: "10px",
    border: "2px solid #d9d9d9",
    display: "inline-block",
    verticalAlign: "bottom",
    marginRight: "10px"

  },
  enteraIcon: {
    width: "20px",
    height: "20px",
    backgroundColor: "white",
    borderBottomRightRadius: "10px",
    borderTopRightRadius: "10px",
    borderBottomLeftRadius: "10px",
    borderTopLeftRadius: "10px",
    border: "2px solid #d9d9d9",
    display: "inline-block",
    verticalAlign: "bottom"

  }
}));

const ComandasBoard = () => {

  const classes = useStyles();


  const { usuario } = useContext(Auth);
  const [nombre, setnombre] = useState(null)

  // const grupos = ["Comenzamos", "Ensaladas y entrantes", "Revueltos y verduras", "Fritos y caseros", "Pescados", "Carnes", "Sugerencias", "Postres", "Vinos"]
  const colorestags = ["", "magenta", "red", "volcano", "orange", "gold", "lime", "green", "cyan", "blue", "geekblue", "purple"]
  const initialValue = []
  const [carta, setCarta] = useState(initialValue);
  const [comandas, setComandas] = useState(initialValue);

  const [mesa, setMesa] = useState(initialValue);
  const [comanda, setComanda] = useState(initialValue);




  const onDataChange = (items) => {
    let comandas = [];

    items.forEach((item) => {
      let key = item.key;
      let data = item.val();
      comandas.push({
        key: key,
        idItemComanda: data.idItemComanda,
        plato: data.plato,
        grupo: data.grupo,
        cantidad: data.cantidad,
        mesa: data.mesa,
        fechayhora: data.fechayhora,
        comentario: data.comentario,
        hecho: data.hecho,
      });
    });
    comandas.sort((a, b) => (a.mesa > b.mesa) ? 1 : -1)


    setComandas(comandas);
  };

  useEffect(() => {
    ComandasDataService.getAll().on("value", onDataChange);

    return () => {
      ComandasDataService.getAll().off("value", onDataChange);
    };
  }, []);


  useEffect(() => {
    usuario ? usuario.displayName ? setnombre(usuario.displayName) : setnombre(usuario.email) : setnombre(null)
  }, [usuario]);

  const marcarComoHecho = (datos) => {
    const data = {
      cantidad: datos.cantidad,
      plato: datos.plato,
      grupo: datos.grupo,
      mesa: datos.mesa,
      idItemComanda: datos.idItemComanda,
      fechayhora: datos.fechayhora,
      comentario: datos.comentario,
      hecho: true,
    }

    ComandasDataService.update(datos.key, data)
      .then(() => {
        console.log("The item was updated successfully!");
      })
      .catch((e) => {
        console.log(e);
      });


  }

  const tiempoColorItemComanda = (item) => {
    let elapsed = Math.ceil((Date.now() - item.fechayhora) / (1000 * 60));
    let color = elapsed > 20 ? "red" : elapsed > 15 ? "orange" : "green";

    return <><ClockCircleTwoTone twoToneColor={color} /> <Moment format="HH:mm" >{item.fechayhora}</Moment></>;
  }

  const reiniciarComandas = () => {
    ComandasDataService.removeAll()
      .then(() => {
        console.log("borradas");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const cerrarComandasMesa = (mesa) => {
    let borrado = false
    comandas && comandas.map((item, key) => {
      if (item.mesa == mesa && item.hecho == true) {
        ComandasDataService.remove(item.key).then(() => {
          borrado = true
        })
          .catch((e) => {
            console.log(e);
          });
      }
    })
    if (borrado) message.success("Borradas comandas de la mesa " + mesa)
  };

  function confirm(e) {
    console.log(e);
    reiniciarComandas()
    message.success('Borradas todas las comandas');
  }

  function cancel(e) {
    console.log(e);
    //message.error('Click on No');
  }


  let actual = "";
  return (
    <>
      
      <MenuAdmin titulo="Comandas" />

      <div className={classes.carta}>

        <Popconfirm
          title="¿Está seguro que quiere borrar todas las comandas?"
          onConfirm={confirm}
          onCancel={cancel}
          okText="Si"
          cancelText="No"
        >
          <Button>Resetear comandas</Button>
        </Popconfirm>
        <>
          {usuario && allowedMails.includes(usuario.email) && <>
            <ul className={classes.pedidoComanda}>
              {comandas.map(item => {
                let tiempoColor = tiempoColorItemComanda(item);
                if (actual !== item.mesa) {
                  actual = item.mesa
                  return <><hr /><p><strong>Mesa</strong> <Tag color={colorestags[item.mesa]}>{item.mesa}</Tag><Button size="small" onClick={(e) => { cerrarComandasMesa(item.mesa) }}>Borrar comandas hechas</Button></p>
                    <li className={item.hecho ? classes.hecho : ""}><div className={classes.itemPedidoComanda}><div>{tiempoColor} {item.cantidad == 'MEDIA' ? <div className={classes.mediaIcon}></div> : <div className={classes.enteraIcon}></div>} <span className="plato">{item.plato}</span></div>{item.comentario ? <Button size="small" danger onClick={(e) => { message.info(item.comentario, 10) }}><ExclamationOutlined /></Button> : ''} {!item.hecho ? <div><Button size="small" onClick={(e) => { marcarComoHecho(item) }} type="default"><CheckOutlined /></Button></div> : ""}</div></li></>

                }
                else {
                  actual = item.mesa
                  return <li className={item.hecho ? classes.hecho : ""}><div className={classes.itemPedidoComanda}><div>{tiempoColor}  {item.cantidad == 'MEDIA' ? <div className={classes.mediaIcon}></div> : <div className={classes.enteraIcon}></div>} <span className="plato">{item.plato}</span></div>{item.comentario ? <Button size="small" danger onClick={(e) => { message.info(item.comentario, 10) }}><ExclamationOutlined /></Button> : ''}  {!item.hecho ? <div><Button size="small" onClick={(e) => { marcarComoHecho(item) }} type="default"><CheckOutlined /></Button></div> : ""}</div></li>
                }

                // return <div> <Moment format="HH:mm" >{item.fechayhora}</Moment> {item.cantidad } {item.plato} {item.mesa} </div>

              })

              }
            </ul>
          </>
          }

        </>
        </div>
    </>
  );

}
export default ComandasBoard;

