import * as firebase from "firebase";
import "firebase/auth";
import "firebase/database";
import 'firebase/storage'

// const config = {
//     apiKey: "AIzaSyAHUwttsX2ius23zxVOXOOcNEDem7FbVAw",
//     authDomain: "ellinderotaberna.firebaseapp.com",
//     databaseURL: "https://ellinderotaberna.firebaseio.com",
//     projectId: "ellinderotaberna",
//     storageBucket: "ellinderotaberna.appspot.com",
//     messagingSenderId: "770099512096",
//     appId: "1:770099512096:web:c149e579529e26d5638d45",
//     measurementId: "G-H9P86CGBK0"
// }

import firebaseConfig from './config/firebase'

const app = firebase.initializeApp(firebaseConfig);


// const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
/*const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();*/

export default app;
// export const db = firebase.database().ref().child('carta');
