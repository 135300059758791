import React, {useContext, useState} from "react";
import {storage} from "./firebaseConfig"

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Dashboard from "./views/Dashboard";
import AltaPlato from "./views/AltaPlato";
import AltaVino from "./views/AltaVino";
import Vinos from "./views/Vinos";
import ModificaPlato from "./views/ModificaPlato";
import ModificaVino from "./views/ModificaVino";
import Login from "./views/Login";
import Carta from "./views/Carta";
import Comanda from "./views/Comanda";
import ComandasBoard from "./views/ComandasBoard";
import Stock from "./views/Stock";
import Home from "./views/Home";
import Error from "./views/Error";
import { AuthContext } from "./context/AuthContext";

import EditarMenu from "./views/EditarMenu";


const App = () => {
    

    

    return (
        <AuthContext>
            <Router>
                <Switch>
                    <Route exact path="/login">
                        <Login />
                    </Route>
                    <Route exact path="/carta">
                        <Carta />
                    </Route>
                    <Route exact path="/comanda">
                        <Comanda />
                    </Route>
                    <Route exact path="/comandasboard">
                        <ComandasBoard />
                    </Route>
                    <Route exact path="/stock">
                        <Stock />
                    </Route>
                    <Route exact path="/platos">
                        <Dashboard />
                    </Route>
                    <Route path="/modificaplato">
                        <ModificaPlato />
                    </Route>
                    <Route exact path="/altaplato">
                        <AltaPlato />
                    </Route>
                    <Route exact path="/editarmenu">
                        <EditarMenu />
                    </Route>
                    <Route exact path="/altavino">
                        <AltaVino />
                    </Route>
                    <Route exact path="/vinos">
                        <Vinos />
                    </Route>
                    <Route path="/modificavino">
                        <ModificaVino />
                    </Route>
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route path="*">
                        <Error />
                    </Route>
                </Switch>
            </Router>
        </AuthContext>
    )
    
}


export default App;
