import React from "react";
import { PageHeader, Button } from "antd";
import app from '../firebaseConfig'
import { Menu, Icon } from 'antd';
import { useHistory } from "react-router-dom";
import { withRouter } from "react-router";

const { SubMenu } = Menu;

const Header = ({ history, titulo, subtitulo }) => {
    return (
        <>
            <PageHeader
                style={{
                    border: "1px solid rgb(235, 237, 240)"
                }}
                onBack={() => window.history.back()}

                title={titulo}
                subTitle={subtitulo}
                extra={[
                    <Button onClick={() => history.push("/")} type="primary"><Icon type="home" />Home</Button>,
                    <Button onClick={() => app.auth().signOut()} key="logout" type="primary"><Icon type="logout" />Cerrar Sesión</Button>,

                ]}
            />
            <Menu mode="horizontal">




                <SubMenu key="SubMenuPlatos" title="Carta de platos">
                    <Menu.Item key="platos">
                        <a href="/platos" rel="noopener noreferrer">
                            Platos</a>
                    </Menu.Item>
                    <Menu.Item key="altaplato">
                        <a href="/altaplato" rel="noopener noreferrer">
                            Añadir plato</a>
                    </Menu.Item>
                </SubMenu>

                <SubMenu key="SubMenuVinos" title="Carta de vinos">
                    <Menu.Item key="vinos">
                        <a href="/vinos" rel="noopener noreferrer">
                            Vinos</a>
                    </Menu.Item>
                    <Menu.Item key="altavino">
                        <a href="/altavino" rel="noopener noreferrer">
                        Añadir vino</a>
                    </Menu.Item>

                </SubMenu>
                <Menu.Item key="editarmenu">
                    <a href="/editarmenu" rel="noopener noreferrer">
                        Menú</a>
                </Menu.Item>


            </Menu>
        </>

    );
}

export default withRouter(Header);
